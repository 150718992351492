import { useTranslation } from 'react-i18next';
import { LoginWraper } from '@/components/login';
import { RegistrationForm } from '@/containers/pages/register/components/RegistrationForm';
import { defaultMailServerPasswordOptions } from '@/mock-data/passwordOptions';

export const Register = () => {
  const { t } = useTranslation();
  return (
    <LoginWraper title={t('register.title')} text={t('')}>
      <RegistrationForm passwordStrengthOptions={defaultMailServerPasswordOptions} />
    </LoginWraper>
  );
};
