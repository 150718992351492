import { IGlobalSettings, IUser } from '../auth';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ITokens, IUserData } from '../auth';

const initialState: Partial<IUserData> = {
  Tokens: {} as ITokens,
  State: '',
  User: {} as IUser,
  Settings: { UserId: -1 } as IGlobalSettings
};

export const userInfoSlice = createSlice({
  name: 'userInfo',
  initialState,
  reducers: {
    setUserTokens(state, action: PayloadAction<ITokens>) {
      state.Tokens = action.payload;
    },
    setUserInfo(state, action: PayloadAction<IUser>) {
      state.User = action.payload;
    },

    setGlobalSettings(state, action: PayloadAction<IGlobalSettings>) {
      state.Settings = action.payload;
    }
  }
});

export const userInfoActions = userInfoSlice.actions;
export const userInfoReducer = userInfoSlice.reducer;
