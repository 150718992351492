import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useAppSelector } from '@hooks/redux/redux';

const RedirectIFAuth = () => {
  const location = useLocation();
  const currentUser = useAppSelector((state) => state.userInfo.User);

  return !currentUser?.Id ? <Outlet /> : <Navigate to="/" state={{ from: location }} replace />;
};

export default RedirectIFAuth;
