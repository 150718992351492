import { useState, useEffect } from 'react';

import { useGetDefaultUserFiltersQuery } from '@/entities/admin-app/users/api';

import { IFilterMenu, IFilterMenuItem } from '@/types/api';
import { IFiltersEnumType } from '@/types/enums';
import { filtersLabelsKeys } from '@/entities/admin-app/constants';
import { useTranslation } from 'react-i18next';

export const useGetActivityFilters = () => {
  const { t } = useTranslation();
  // FIXME
  const { data: defaultFiltersData, isSuccess: defaultFiltersSuccess } =
    useGetDefaultUserFiltersQuery(20);

  const [filtersMenuList, setFiltersMenuList] = useState<IFilterMenu[]>([]);

  useEffect(() => {
    if (!(defaultFiltersData && defaultFiltersSuccess)) {
      return;
    }
    const filialsFilter = defaultFiltersData.find(
      (el) => el.Type === IFiltersEnumType.FilialFilter
    );
    if (!filialsFilter) {
      return;
    }

    setFiltersMenuList(
      [
        {
          label: t('statistics.date'),
          Type: IFiltersEnumType.DateOfCreateFilter,
          Items: [] as IFilterMenuItem[],
          hideSearch: true
          // Count: undefined,
          // checked: false
        }
      ].concat([
        {
          ...filialsFilter,
          label:
            filialsFilter.Type in IFiltersEnumType
              ? t(`filters.${filtersLabelsKeys[filialsFilter.Type]}`)
              : t('statistics.filial'),
          Items: filialsFilter.Items?.map((filterItem) => ({ ...filterItem, checked: false })),
          hideSearch: false
        }
      ])
    );
  }, [defaultFiltersData, defaultFiltersSuccess]);

  return { filtersMenuList };
};
