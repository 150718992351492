import { ROUTES } from '@/containers/routes/constants';
import { ITransformedRoute } from '@/types';
import { useTranslation } from 'react-i18next';
export const deviceTypeToString = (deviceType: number): string => {
  const { t } = useTranslation();
  return deviceType === 0
    ? t('statistics.another')
    : deviceType === 1
    ? t('statistics.dekstop')
    : deviceType === 2
    ? t('statistics.mobile')
    : t('statistics.another');
};

export const getStatisticsRoutes = (): ITransformedRoute[] => {
  const { fullPath, links } = ROUTES.statistics;
  return Object.values(links).map((value) => {
    const path = `${fullPath}/${value ? `${value}` : ''}`;
    return { path, chunk: value };
  });
};
