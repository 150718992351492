import { FC } from 'react';
import { Text } from '@mantine/core';
import { IRowData } from '@components/simple-table/types';
import { getNameByRolesId } from '../../../utils';
import { useTranslation } from 'react-i18next';

interface IItemRolesProps {
  item: IRowData;
}

export const Type: FC<IItemRolesProps> = ({ item }) => {
  const { t } = useTranslation();
  const { name } = getNameByRolesId(item?.Type);
  return <Text>{name.length ? t(`roles.types.${name}`) : ''}</Text>;
};
