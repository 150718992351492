import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IFilialQuota, IQuota, IQuotasData } from './types';

interface IQuotas {
  quotas: IQuota[];
  filialQuota?: IFilialQuota;
  totalQuotas: number;
  quotasFilters: {
    Count: number;
    Offset: number;
  };
  selectedQuota: IQuota | null;
}

const initialState: IQuotas = {
  quotas: [],
  filialQuota: {} as IFilialQuota,
  totalQuotas: 0,
  quotasFilters: {
    Count: 10,
    Offset: 0
  },
  selectedQuota: null
};

export const quotasSlice = createSlice({
  name: 'quotas',
  initialState,
  reducers: {
    setQuotas(state, action: PayloadAction<IQuotasData>) {
      state.quotas = action.payload.Quotas;
      state.totalQuotas = action.payload.TotalCount;
    },
    setFilialQuota(state, action: PayloadAction<IFilialQuota>) {
      state.filialQuota = action.payload;
    },
    setQuotasFilters(state, action) {
      state.quotasFilters = action.payload;
    },
    setSelectedQuota(state, action: PayloadAction<IQuota | null>) {
      state.selectedQuota = action.payload;
    }
  }
});

export const quotasActions = quotasSlice.actions;
export const quotasReducer = quotasSlice.reducer;
