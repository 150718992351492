import { Navigate, Outlet, useLocation } from 'react-router-dom';

import { useAppSelector } from '@hooks/redux/redux';
import { isAdmin } from '@/lib/utils/access';
import { ROUTES } from '@/containers/routes/constants';

const RequireAuth = () => {
  const location = useLocation();

  const currentUser = useAppSelector((state) => state.userInfo.User);

  if (!currentUser?.Id) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  if (!isAdmin(currentUser) && !location.pathname.startsWith(ROUTES.profile.path)) {
    return (
      <>
        <Outlet /> <Navigate to={ROUTES.dashboard.fullPath} />
      </>
    );
  }

  return <Outlet />;
};

export default RequireAuth;
