import { SimpleGrid } from '@mantine/core';
import { FC, useContext, useEffect } from 'react';
import { EditUserInfoContext } from '../../../index';
import { SectionTemplate } from '@/containers/pages/user/components/section-template';
import { ResetPasswordSection } from '../../sections/profile-header/reset-password';
import { Ldap } from '@/containers/pages/user/feature/create-user/components/sections/general-info/ldap';
import { ValidateTextInput } from '@/containers/pages/user/components/validateFields/validateTextInput';
import { ActiveConnections } from '../../sections/active-connections';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from '@mantine/hooks';
import { useForm } from '@mantine/form';
import { IUserCredentials } from '@/entities/admin-app/user';
import { useAppSelector } from '@hooks/redux/redux';

const Safety: FC = () => {
  const { t } = useTranslation();
  const { currentId, userInfoCommon } = useContext(EditUserInfoContext);
  const isMobile = useMediaQuery('(max-width: 576px)');
  const User = useAppSelector((state) => state?.userInfo?.User);
  const { LdapIdentifier, Credentials } = userInfoCommon;

  const credentialsForm = useForm<IUserCredentials>({
    initialValues: {
      Login: '',
      Email: '',
      PasswordResetUrl: '',
      AdditionalEmail: ''
    }
  });

  const formData = {
    Login: Credentials?.Login || '',
    Email: Credentials?.Email || '',
    PasswordResetUrl: Credentials?.PasswordResetUrl || '',
    AdditionalEmail: Credentials?.AdditionalEmail || ''
  };

  useEffect(() => {
    credentialsForm.setValues(formData);
    credentialsForm.resetDirty(formData);
  }, [Credentials]);

  return (
    <>
      <SectionTemplate title={t('profile.localAuthorization')}>
        <SimpleGrid cols={isMobile ? 1 : 2} w="100%">
          <ValidateTextInput
            fieldName="Login"
            label={t('profile.login')}
            form={credentialsForm}
            disabled={true}
            dataAttribute="safetyLogin"
          />

          <ValidateTextInput
            placeholder={t('profile.noEmail')}
            fieldName="Email"
            label={t('profile.email')}
            form={credentialsForm}
            isConfirmed={Credentials.IsConfirmed}
            userId={currentId}
            disabled={true}
            dataAttribute="safetyEmail"
          />
        </SimpleGrid>
        <ResetPasswordSection
          currentId={currentId}
          defaultLink={Credentials?.PasswordResetUrl}
          userInfoCommon={userInfoCommon}
          userId={User?.Id}
        />
      </SectionTemplate>
      {LdapIdentifier && (
        <SectionTemplate>
          <Ldap LdapIdentifier={LdapIdentifier} isLimitedAccess={true} />
        </SectionTemplate>
      )}
      <ActiveConnections />
    </>
  );
};

export default Safety;
