import {
  PasswordInput,
  Progress,
  PasswordInputProps,
  Box,
  ActionIcon,
  Tooltip,
  Text
} from '@mantine/core';
import { IconRefresh } from '@tabler/icons';
import { FC, SyntheticEvent, useRef } from 'react';
import { getPasswordStrength, getStrength } from '@/utils/passwordUtils';
import { useStyles } from './styles';
import { useTranslation } from 'react-i18next';
import { useDisclosure } from '@mantine/hooks';
import { preserveCursorPosition } from '@/utils/preserveCursorPosition';

interface IPasswordInput extends PasswordInputProps {
  value: string;
  label: string;
  placeholder?: string;
  onChange: (event: SyntheticEvent<HTMLInputElement>) => void;
  handleGeneratePassword: () => void;
  size?: string;
}

export const PasswordInputStrengthMeter: FC<IPasswordInput> = ({
  value,
  label,
  placeholder,
  onChange,
  handleGeneratePassword,
  size = 'sm',
  error = ''
}) => {
  const { classes } = useStyles();
  const strength = getStrength(value);
  const { color, strengthString } = getPasswordStrength(strength);
  const [visible, { toggle }] = useDisclosure(false);
  const passwordInputStrengthRef = useRef<HTMLInputElement | null>(null);

  const { t } = useTranslation();

  const fixCursorLocation = (ref: React.RefObject<HTMLInputElement>) => {
    return () => {
      preserveCursorPosition(ref, toggle);
    };
  };

  return (
    <Box className={classes.container}>
      <Box>
        <PasswordInput
          size={size}
          radius="md"
          variant="filled"
          data-value="password"
          label={label}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          className={classes.input}
          withAsterisk={true}
          error={error}
          ref={passwordInputStrengthRef}
          visible={visible}
          onVisibilityChange={fixCursorLocation(passwordInputStrengthRef)}
        />
      </Box>

      <Box className={classes.field}>
        <Box className={classes.passwordProgress}>
          <Text className={classes.passwordStatus} fz="sm">{`${t(
            'passwordReset.passwordStrength'
          )}: ${strengthString}`}</Text>
          <Progress color={color} value={strength} size={5} className={classes.progress} />
        </Box>
        <Tooltip label={t('requestResetPassword.generateStrongPassword')}>
          <ActionIcon className={classes.icon} onClick={handleGeneratePassword}>
            <Box className={classes.generateButton}>
              <IconRefresh />
              {t('passwordReset.create')}
            </Box>
          </ActionIcon>
        </Tooltip>
      </Box>
      <Text fz="xs" className={classes.hint}>
        {t('passwordReset.createRules')}
      </Text>
    </Box>
  );
};
