import React, { FC } from 'react';
import { useAppSelector } from '@hooks/redux/redux';
import { CommonSection } from './common';

const BrandingPage: FC = () => {
  const { CommonViewSettings } = useAppSelector((state) => state.commonSettings);
  return <CommonSection CommonViewSettings={CommonViewSettings} />;
};

export default BrandingPage;
