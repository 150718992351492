import { FC } from 'react';
import TimeZones from './timeZones';
import Language from './language';

const UserSettings: FC = () => {
  return (
    <>
      <TimeZones />
      <Language />
    </>
  );
};

export default UserSettings;
