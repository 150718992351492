import { ILDAPForm } from '@/containers/pages/filials/edit/types';

export const initialLDapFormValues: ILDAPForm = {
  useLDAP: 'false',
  server: '',
  login: '',
  password: '',
  Port: 0,
  DN: '',
  Domain: '',
  UserFilter: '',
  UseSsl: false,
  mapping: {
    principal_name: '',
    login: '',
    first_name: '',
    second_name: '',
    middle_name: '',
    email: '',
    phone: '',
    birthdate: '',
    position: '',
    organization: '',
    city: '',
    isDeleted: ''
  },
  GroupEnabled: 'false',
  GroupDn: '',
  GroupFilter: '',
  GroupMapping: {
    Description: '',
    Name: '',
    Uid: '',
    Member: ''
  }
};
