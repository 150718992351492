import React, { FC, useContext } from 'react';
import { RolesInfoMainContext } from '..';
import { PanelsEnum } from '@/types/enums/roles';
import { HeaderPanel } from '../../components/panel-header';
import { EditRolesItem } from '../../components/edite-item';
import { canEditRole } from '@/lib/utils/access';
import { Flex, Loader } from '@mantine/core';
import { useTranslation } from 'react-i18next';

export const MoreInfoPanel: FC = () => {
  const { t } = useTranslation();
  const { rolesInfoCommon } = useContext(RolesInfoMainContext);

  return (
    <>
      <HeaderPanel title={t('roles.edit')} activePanel={PanelsEnum.generalInformation} />

      {canEditRole(rolesInfoCommon?.Type) && rolesInfoCommon ? (
        <EditRolesItem editedItem={rolesInfoCommon} />
      ) : (
        <Flex align="center" justify="center" mt="200px">
          <Loader size="lg" />
        </Flex>
      )}
    </>
  );
};
