import React, { FC, PropsWithChildren, useState } from 'react';
import { Outlet } from 'react-router-dom';

import { ErrorBoundary } from '@components/error-boundary';
import { SimpleNavbar } from '@components/simple-navbar';

import { Layout } from '../../layout';
import { NavMenu } from './nav-menu';
import { useStyles } from './styles';
import { Box, Burger } from '@mantine/core';

export const Statistics: FC<PropsWithChildren> = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { classes } = useStyles();

  return (
    <ErrorBoundary componentTitle="Statistics page">
      <Layout
        leftNavbarContent={
          <SimpleNavbar
            data-cy="statisticsMenu"
            widthNav={290}
            style={{ flexShrink: 0, borderRight: 'none' }}
            className={classes.simpleNavbar}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
          >
            <NavMenu setIsOpen={setIsOpen} />
          </SimpleNavbar>
        }
      >
        <Box className={classes.headerMenu}>
          <Box className={classes.headerMenuBurger}>
            <Burger opened={isOpen} onClick={() => setIsOpen(true)} size="sm" />
          </Box>
          <Outlet />
        </Box>
        <Box className={classes.section}>{children}</Box>
      </Layout>
    </ErrorBoundary>
  );
};
