import React, { FC, useCallback, useContext } from 'react';
import { ActionIcon, Box, Text } from '@mantine/core';
import { useStyles } from './styles';
import { IconArrowLeft } from '@tabler/icons';
import { EditIcon } from '../../../../../../../assets/icons';
import { PanelsEnum } from '../../../../../../../types/enums/user';
import { useNavigate } from 'react-router-dom';
import { UserInfoMainContext } from '../../panels';

interface IHeaderPanelProps {
  title: string;
  activePanel?: PanelsEnum;
  rightIcon?: boolean;
}

export const HeaderPanel: FC<IHeaderPanelProps> = ({ title, activePanel, rightIcon = true }) => {
  const { classes } = useStyles();
  const { selectedUserId, setActivePanel } = useContext(UserInfoMainContext);

  const navigate = useNavigate();

  const goBack = useCallback(() => {
    setActivePanel(PanelsEnum.generalInformation);
  }, []);

  const goEditUser = () => {
    navigate(`/profile/${selectedUserId}`, {
      state: activePanel
    });
  };

  return (
    <Box className={classes.headerPanel}>
      <Box className={classes.container}>
        <ActionIcon onClick={goBack} size="md">
          <IconArrowLeft color="#667085" />
        </ActionIcon>
        <Text className={classes.title}>{title}</Text>
      </Box>

      {rightIcon && (
        <ActionIcon size="md" onClick={goEditUser}>
          <EditIcon />
        </ActionIcon>
      )}
    </Box>
  );
};
