import { createApi } from '@reduxjs/toolkit/query/react';
import { IFullResponse } from '@/types/api';
import { BASE_URL } from '../constants';
import { IClientData, IQueryRegister } from './types';
import { customBaseQuery } from '@/utils/customBaseQuery';

export const registerApi = createApi({
  baseQuery: customBaseQuery(`${BASE_URL}/v2/customer`),
  refetchOnFocus: false,
  endpoints: (build) => ({
    createClient: build.mutation<IClientData, IQueryRegister>({
      query: (payload?: IQueryRegister) => ({
        url: `/register`,
        body: payload,
        method: 'post'
      }),
      transformResponse: (response: IFullResponse<IClientData>) => ({
        ...response.Response.Data
      })
    })
  })
});

export const { useCreateClientMutation } = registerApi;
