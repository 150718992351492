import { ErrorBoundary } from '@components/error-boundary';
import { ModulesEnum } from '@/types/enums/filials';
import { useAppSelector } from '@/hooks/redux/redux';
import CommonModuleContainer from '@/containers/pages/filials/edit/components/common/modules';

const DrawModule = () => {
  const { filial } = useAppSelector((state) => state.filials);

  return (
    <ErrorBoundary componentTitle="Draw odule page">
      <CommonModuleContainer filial={filial} currentModule={ModulesEnum.Draw} />
    </ErrorBoundary>
  );
};

export default DrawModule;
