import { PropsWithChildren } from 'react';
import { useStyles } from './styles';

type Props = PropsWithChildren;

export const Controls = ({ children }: Props) => {
  const { classes } = useStyles();

  return <div className={classes.container}>{children}</div>;
};
