import { createApi } from '@reduxjs/toolkit/query/react';
import { BASE_URL } from '../constants';
import { IGetModules, IModuleAccess, ITableModuleAccess } from './types';
import { customBaseQuery } from '@/utils/customBaseQuery';
import { ModulesEnum, TagEnum, tagTypes } from '@/types/enums/filials';
import { IFullResponse } from '@/types/api';

export const modulesApi = createApi({
  reducerPath: 'api/modules',
  baseQuery: customBaseQuery(`${BASE_URL}`),

  refetchOnFocus: false,
  tagTypes: tagTypes,
  endpoints: (build) => ({
    getModuleRoles: build.query<IModuleAccess[], IGetModules>({
      query: (payload: IGetModules) => ({
        url: `/v2/moduleaccess/roles?module=${payload.module}&customerId=${payload.id}`,
        method: 'get'
      }),
      transformResponse: (response: IFullResponse<{ Items: ITableModuleAccess[] }>) => {
        const { Items } = response.Response.Data;
        return Items.map((role) => ({
          id: role.Id,
          name: role.Name
        }));
      },
      providesTags: (result, err, arg) => [
        arg.module === ModulesEnum.Mail ? TagEnum.emailRoles : TagEnum.calendarRoles
      ]
    }),
    getModuleUsers: build.query<IModuleAccess[], IGetModules>({
      query: (payload: IGetModules) => ({
        url: `/v2/moduleaccess/users?module=${payload.module}&customerId=${payload.id}`,
        method: 'get'
      }),
      transformResponse: (response: IFullResponse<{ Items: ITableModuleAccess[] }>) => {
        const { Items } = response.Response.Data;
        return Items.map((role) => ({
          id: role.Id,
          name: role.Name
        }));
      },
      providesTags: (result, err, arg) => [
        arg.module === ModulesEnum.Mail ? TagEnum.emailUsers : TagEnum.calendarUsers
      ]
    }),
    addModuleRole: build.mutation<IModuleAccess, { module: ModulesEnum; roleId: number }>({
      query: ({ module, roleId }) => ({
        url: '/v2/moduleaccess/roles',
        body: {
          Module: module,
          Ids: [roleId]
        },
        method: 'post'
      }),
      invalidatesTags: (result, error, arg) => [
        arg.module === ModulesEnum.Mail ? TagEnum.emailRoles : TagEnum.calendarRoles
      ]
    }),
    addModuleUser: build.mutation<IModuleAccess, { module: ModulesEnum; userId: number }>({
      query: ({ module, userId }) => ({
        url: '/v2/moduleaccess/users',
        body: {
          Module: module,
          Ids: [userId]
        },
        method: 'post'
      }),
      invalidatesTags: (result, error, arg) => [
        arg.module === ModulesEnum.Mail ? TagEnum.emailUsers : TagEnum.calendarUsers
      ]
    }),
    deleteModuleRole: build.mutation<IModuleAccess, { module: ModulesEnum; roleId: number }>({
      query: ({ module, roleId }) => ({
        url: '/v2/moduleaccess/roles',
        body: {
          Module: module,
          Ids: [roleId]
        },
        method: 'delete'
      }),
      invalidatesTags: (result, error, arg) => [
        arg.module === ModulesEnum.Mail ? TagEnum.emailRoles : TagEnum.calendarRoles
      ]
    }),
    deleteModuleUser: build.mutation<IModuleAccess, { module: ModulesEnum; userId: number }>({
      query: ({ module, userId }) => ({
        url: '/v2/moduleaccess/users',
        body: {
          Module: module,
          Ids: [userId]
        },
        method: 'delete'
      }),
      invalidatesTags: (result, error, arg) => [
        arg.module === ModulesEnum.Mail ? TagEnum.emailUsers : TagEnum.calendarUsers
      ]
    })
  })
});

export const {
  useGetModuleRolesQuery,
  useGetModuleUsersQuery,
  useAddModuleRoleMutation,
  useAddModuleUserMutation,
  useDeleteModuleRoleMutation,
  useDeleteModuleUserMutation
} = modulesApi;
