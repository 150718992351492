import {
  ActionIcon,
  Box,
  Button,
  Checkbox,
  Input,
  LoadingOverlay,
  SimpleGrid
} from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { useStyles } from '../../../../../styles';
import { IconPlus } from '@tabler/icons';
import { SelectGroupSimpleModal } from '@/components/modal/select-group-simple';
import { SelectQuotas } from '@/containers/pages/user/components/Quotaselect';
import { FormErrors, useForm } from '@mantine/form';
import { Dispatch, FC, SetStateAction, useState } from 'react';
import { Modal } from '@components/modal';
import { useAppSelector } from '@/hooks/redux/redux';
import { themeColors } from '@/theme/colors';
import { Badges } from '@/containers/pages/roles/components/badges';
import { getValidateErrors } from '@/lib/utils/validation';
import { z } from 'zod';
import { useSetQuotaByRoleMutation } from '@/entities/admin-app/quotas/api';
import { ISetQuotaByRolePayload } from '@/entities/admin-app/quotas';
import { universalNotification } from '@/lib/utils/notification';
import { clearApiCacheByTags } from '@/entities/helpers';
import { usersApi } from '@/entities/admin-app/users/api';

export interface IBadges {
  label: string;
}

interface IMassAddQuotaInterface {
  isOpened: boolean;
  setIsModalOpened: Dispatch<SetStateAction<boolean>>;
}
export const MassAddQuotaForm: FC<IMassAddQuotaInterface> = ({ isOpened, setIsModalOpened }) => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const { filial } = useAppSelector((state) => state.filials);
  const [isRoleModalOpen, setIsRoleModalOpen] = useState(false);
  const [selectedRoles, setSelectedRoles] = useState<IBadges | null>(null);
  const [setQuotaByRole, { isLoading }] = useSetQuotaByRoleMutation();
  const validateScheme = z.object({
    RoleId: z.number(),
    QuotaId: z.number({
      invalid_type_error: t('validation.quotaMandatory')
    })
  });

  const form = useForm<ISetQuotaByRolePayload>({
    initialValues: {
      RoleId: null,
      QuotaId: null,
      IsQuotaDown: false
    },
    validateInputOnChange: false,
    validate: (values: ISetQuotaByRolePayload): FormErrors =>
      getValidateErrors(validateScheme, values)
  });

  const closeRoleModal = () => {
    setIsRoleModalOpen(false);
  };

  const setRoleId = async (id: number) => {
    form.setFieldValue('RoleId', id);
    closeRoleModal();
  };

  const setRolesGroup = async (group: IBadges) => {
    setSelectedRoles(group);
  };

  const handleRemoveRoles = () => {
    setSelectedRoles(null);
    form.setFieldValue('RoleId', null);
  };

  const closeModal = () => {
    setIsModalOpened(false);
    form.reset();
    setSelectedRoles(null);
  };

  const handleSubmit = async (values: ISetQuotaByRolePayload) => {
    try {
      const res = await setQuotaByRole(values);
      if ('error' in res) {
        universalNotification({
          error: `${(res as any)?.error}`,
          isError: true,
          isSuccess: false
        });
      } else {
        universalNotification({
          isSuccess: true,
          isError: false,
          error: null,
          successTitle: t('filials.diskSpace.setQuotasSuccess')
        });
        clearApiCacheByTags(usersApi, ['Users']);
      }
      form.reset();
      closeModal();
    } catch (error) {
      console.error(error);
    }
  };

  const isDisabled = Object.keys(form.errors).length !== 0 || !form.isDirty();

  return (
    <Modal
      opened={isOpened}
      title={t('filials.diskSpace.addQuotaByRole')}
      onClose={() => {
        closeModal();
      }}
    >
      <form className={classes.modalRoot} onSubmit={form.onSubmit(handleSubmit)}>
        <Box className={classes.modalContainer}>
          <SimpleGrid cols={1} w="100%">
            <SimpleGrid cols={1} spacing="xs">
              <Input
                classNames={{
                  wrapper: classes.inputWrapper,
                  input: classes.buttonInput
                }}
                component="button"
                type="button"
                pointer
                radius="8px"
                rightSection={
                  <ActionIcon onClick={() => setIsRoleModalOpen(true)} mr="5px">
                    <IconPlus color={themeColors.brandGray[0]} />
                  </ActionIcon>
                }
                onClick={() => setIsRoleModalOpen(true)}
                error={form.getInputProps('RoleId').error}
              >
                {t('roles.chooseRole')}
              </Input>

              {selectedRoles && (
                <Box>
                  <Badges label={selectedRoles?.label} onRemove={handleRemoveRoles} />
                </Box>
              )}
            </SimpleGrid>
            <SelectGroupSimpleModal
              onClose={closeRoleModal}
              isOpened={isRoleModalOpen}
              title={t('roles.chooseRole')}
              setGroupId={setRoleId}
              setGroup={setRolesGroup}
              filial={filial}
              {...form.getInputProps('RoleId')}
            />
            <SelectQuotas
              form={form}
              fieldName="QuotaId"
              value={form.values.QuotaId}
              Customer={filial?.Id}
              label={t('profile.selectDefaultQuota')}
              notClearable
            />
            <Checkbox
              classNames={{ input: classes.checkInput, label: classes.checkLabel }}
              label={t('filials.diskSpace.massQuotaHint')}
              {...form.getInputProps('IsQuotaDown', { type: 'checkbox' })}
            />
          </SimpleGrid>
        </Box>
        <Box className={classes.modalFooter}>
          <Button type="submit" size="sm" radius={8} fullWidth disabled={isDisabled}>
            {t('filials.diskSpace.assignQuotas')}
          </Button>
        </Box>
      </form>
      <LoadingOverlay visible={isLoading} />
    </Modal>
  );
};
