import { useState } from 'react';
import { Text, Flex } from '@mantine/core';
import { IColumnData, IRowData } from '@components/simple-table/types';
import { IQuota } from '@/entities/admin-app/quotas';
import { Action } from '../components/table/action';
import { formatDataSizeUnits } from '@/lib/utils/format';
import { useTranslation } from 'react-i18next';

interface IUseGetColumns {
  onEdit: (quota: IQuota) => void;
}

export const useGetColumns = ({ onEdit }: IUseGetColumns) => {
  const { t } = useTranslation();
  const columnsData: IColumnData[] = [
    {
      width: '33%',
      dataIndex: 'name',
      title: t('filials.diskSpace.table.name'),
      render: (item: IRowData) => item.name
    },
    {
      width: '20%',
      dataIndex: 'size',
      title: t('filials.diskSpace.table.size'),
      render: (item: IRowData) => formatDataSizeUnits(item.size)
    },
    {
      width: '10%',
      dataIndex: 'users',
      title: t('filials.diskSpace.table.users'),
      render: (item: IRowData) => <Text>{item.users || ''}</Text>
    },
    {
      width: 'auto',
      dataIndex: 'action',
      title: '',
      render: (item: IRowData) => (
        <Flex justify="flex-end">
          <Action quota={item as IQuota} onEdit={onEdit} />
        </Flex>
      )
    }
  ];

  const [columns, setColumns] = useState<IColumnData[]>(columnsData);

  return { columns, setColumns };
};
