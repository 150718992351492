import { useState, useEffect } from 'react';

import { SearchIcon } from '@assets/icons';
import { Search } from '@components/search/search-input';

import { useDebounce } from '@hooks/useDebounce';
import { useActions } from '@hooks/redux/action';
import { useAppSelector } from '@hooks/redux/redux';
import { useTranslation } from 'react-i18next';
import { useStyles } from '../../styles';

export const FilialSearch = () => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { setFilialsTableFilters } = useActions();
  const { filters: filialsFilter } = useAppSelector((state) => state.filials);

  const [text, setText] = useState(filialsFilter?.Query || '');

  const debounced = useDebounce(text, 500);

  useEffect(() => {
    setFilialsTableFilters({
      ...filialsFilter,
      Offset: 0,
      Query: debounced
    });
  }, [debounced]);

  return (
    <Search
      icon={<SearchIcon />}
      data-cy="searchFilials"
      setSearch={setText}
      className={classes.search}
      fullSize
      value={text}
      placeholder={t('filials.search')}
    />
  );
};
