import { useContext } from 'react';
import { Box } from '@mantine/core';
import { useStyles } from './styles';
import { TextField } from '@components/fields/text-field';
import { TextFieldTags } from '@components/fields/text-field-tags';
import { RoleTypesEnum } from '@/types/enums/roles';
import { RolesInfoMainContext } from '../../panels';
import { getNameByRolesId } from '../../../../utils';
import { formatDateTime } from '@/lib/utils/date';
import { useTranslation } from 'react-i18next';

export const BaseInfo = () => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const { rolesInfoCommon } = useContext(RolesInfoMainContext);

  const { name } = getNameByRolesId(rolesInfoCommon?.Type as RoleTypesEnum);
  return (
    <Box className={classes.content}>
      <TextField
        title={t('roles.roleType')}
        description={name.length ? t(`roles.types.${name}`) : ''}
      />
      <TextField title={t('unit')} description={rolesInfoCommon?.Customer?.Name} />
      <TextField title={t('roles.parentalRole')} description={rolesInfoCommon?.Parent?.Name} />
      <TextField title={t('roles.roleLeader')} description={rolesInfoCommon?.Owner?.Name} />
      {/* <TextFieldTags title="Роли" tags={rolesInfoCommon?.Roles} /> */}
      <TextFieldTags title={t('roles.childRoles')} tags={rolesInfoCommon?.Children} />
      <TextField
        title={t('dateOfCreation')}
        description={formatDateTime(rolesInfoCommon?.CreateOfDate)}
      />
    </Box>
  );
};
