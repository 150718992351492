import { FC, useCallback, useContext } from 'react';
import { Link } from 'react-router-dom';
import { ActionIcon, Box } from '@mantine/core';

import { EditIcon } from '@assets/icons';

import { FilialsInfoMainContext } from '../../panels';
import { Action } from '../../../table/action/index';

import { useStyles } from './styles';
import { IconArrowLeft } from '@tabler/icons';
import { useActions } from '@/hooks/redux/action';

export const DrawerHeader: FC = () => {
  const { classes } = useStyles();
  const { filialsInfoCommon } = useContext(FilialsInfoMainContext);
  const { setSelectedFilialsId } = useActions();

  const goBack = useCallback(() => {
    setSelectedFilialsId(0);
  }, []);

  return (
    <>
      <ActionIcon onClick={goBack} size="md" className={classes.backArrow}>
        <IconArrowLeft color="#667085" />
      </ActionIcon>
      <Box className={classes.action}>
        <Link to={`${filialsInfoCommon?.Id || ''}`}>
          <ActionIcon size="md">
            <EditIcon />
          </ActionIcon>
        </Link>
        <Action item={filialsInfoCommon} />
      </Box>
    </>
  );
};
