import { IFilterConditionItem } from '@/types/api';
import { IFiltersEnumType } from '@/types/enums';
import { IRowData, IDiskSpaceSortField } from '@components/simple-table/types';

export const convertToFilterConditions = (
  activeFilters: Record<IFiltersEnumType, number[] | Date[]>
): IFilterConditionItem[] => {
  const filterObjects: IFilterConditionItem[] = [];

  for (const [key, value] of Object.entries(activeFilters)) {
    const filterType = +key;
    const filterObject: IFilterConditionItem = {
      Type: filterType
    };

    if (filterType === IFiltersEnumType.DateOfCreateFilter) {
      const [from, to] = value as Date[];
      filterObject.DateRange = {
        From: from?.toISOString(),
        To: to.toISOString()
      };
    } else {
      filterObject.NumValues = value as number[];
    }

    filterObjects.push(filterObject);
  }

  return filterObjects;
};

export const transformNumberToSortField = (value: IDiskSpaceSortField): keyof IRowData => {
  return value in IDiskSpaceSortField
    ? IDiskSpaceSortField[value]
    : IDiskSpaceSortField[IDiskSpaceSortField?.None];
};

export const transformSortFieldToNumber = (
  value: keyof typeof IDiskSpaceSortField
): IDiskSpaceSortField => {
  return getValueByKeyForNumberEnum(value);
};

function getValueByKeyForNumberEnum(value: keyof typeof IDiskSpaceSortField) {
  const valueFromKey = Object.entries(IDiskSpaceSortField).find(([key]) => key === value)?.[1];
  return typeof valueFromKey === 'number' && valueFromKey in IDiskSpaceSortField
    ? valueFromKey
    : IDiskSpaceSortField.None;
}
