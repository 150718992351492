import { FC, useId } from 'react';
import { Input, InputProps } from '@mantine/core';
import InputMask from 'react-input-mask';
import { useStyles } from './styles';
import { UseFormReturnType } from '@mantine/form';

interface IPhoneProps {
  label?: string;
  mask?: string | Array<string | RegExp>;
  error?: string;
  inputProps?: InputProps;
  className?: string;
  form: UseFormReturnType<any>;
  fieldName: string;
}

export const Phone: FC<IPhoneProps> = ({ form, fieldName, label, mask, className, inputProps }) => {
  const { classes, cx } = useStyles();
  const fieldPhoneId = useId();

  return (
    <Input.Wrapper
      id={fieldPhoneId}
      error={form.getInputProps(fieldName).error}
      className={cx(classes.wrapper, className)}
      label={label}
    >
      {mask ? (
        <Input
          id={fieldPhoneId}
          component={InputMask}
          mask={mask}
          {...inputProps}
          value={form.getInputProps(fieldName).value || ''}
          error={form.getInputProps(fieldName).error}
          data-name={fieldName}
        />
      ) : (
        <Input
          data-cy="additionalPhone"
          id={fieldPhoneId}
          {...inputProps}
          value={form.getInputProps(fieldName).value || ''}
          error={form.getInputProps(fieldName).error}
          data-name={fieldName}
        />
      )}
    </Input.Wrapper>
  );
};
