import { useTranslation } from 'react-i18next';
import i18n from '@/entities/i18next';
import { DrawInfoPanelsEnum, EmailInfoPanelsEnum } from '@/types/enums/modules';
import { FilialInfoPanelsEnum } from '@/types/enums/filials';

export const useGetInfoSections = () => {
  const { t } = useTranslation();
  const driveInfoSections = [
    // { label: 'Квоты', value: DriveInfoPanelsEnum.quotas },
    // { label: 'Внешние ссылки', value: DriveInfoPanelsEnum.externalLinks }
  ] as const;

  const emailInfoSections = [
    { label: t('filials.modules.rolesAccess'), value: EmailInfoPanelsEnum.byRoles },
    { label: t('filials.modules.userAccess'), value: EmailInfoPanelsEnum.byUsers }
  ] as const;

  const calendarInfoSections = [
    { label: t('filials.modules.rolesAccess'), value: EmailInfoPanelsEnum.byRoles },
    { label: i18n.t('filials.modules.userAccess'), value: EmailInfoPanelsEnum.byUsers }
  ] as const;

  const drawInfoSections = [
    { label: t('filials.modules.rolesAccess'), value: DrawInfoPanelsEnum.byRoles },
    { label: t('filials.modules.userAccess'), value: DrawInfoPanelsEnum.byUsers }
  ] as const;
  const contactsInfoSections = [
    { label: t('filials.modules.rolesAccess'), value: DrawInfoPanelsEnum.byRoles },
    { label: t('filials.modules.userAccess'), value: DrawInfoPanelsEnum.byUsers }
  ] as const;

  const securityInfoSections = [
    {
      label: t('filialSecurity.ipSecurityForUsers'),
      value: FilialInfoPanelsEnum.ipSecurityUsers
    },
    {
      label: t('filialSecurity.ipSecurityAdministration'),
      value: FilialInfoPanelsEnum.ipSecurityAdm
    },
    { label: t('filialSecurity.sessionTitle'), value: FilialInfoPanelsEnum.sessionDuration },
    { label: t('filialSecurity.passwordTitle'), value: FilialInfoPanelsEnum.passwordLimit }
    // {
    //   label: 'Настройки надежности пароля',
    //   value: FilialInfoPanelsEnum.passwordSecurity
    // }
  ] as const;

  return {
    drawInfoSections,
    calendarInfoSections,
    contactsInfoSections,
    emailInfoSections,
    driveInfoSections,
    securityInfoSections
  };
};
