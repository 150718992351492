import { IListItem } from '@/types';
import { PanelsEnum } from '@/types/enums/user';
import { useTranslation } from 'react-i18next';

export const useGetInfoSectionItems = () => {
  const { t } = useTranslation();
  const createUserInfoSections: IListItem[] = [
    { label: t('users.status'), value: PanelsEnum.status },
    { label: t('profile.credentials'), value: PanelsEnum.credentials },
    { label: t('profile.generalInformation'), value: PanelsEnum.personalData },
    { label: t('profile.unit'), value: PanelsEnum.company },
    { label: t('profile.position'), value: PanelsEnum.positions },
    { label: t('profile.roles'), value: PanelsEnum.roles },
    { label: t('profile.mailAccounts'), value: PanelsEnum.mailAccount },
    { label: t('profile.retentionPolicy'), value: PanelsEnum.disk }
  ];
  return { createUserInfoSections };
};
