import { FC } from 'react';
import { TextInput } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { useStyles } from './styles';

interface IAddressProps {
  defaultValue?: string;
  form?: UseFormReturnType<any>;
  fieldName: string;
  label: string;
  placeholder?: string;
  disabled?: boolean;
}

export const Address: FC<IAddressProps> = ({ form, fieldName, label, disabled, placeholder }) => {
  const { classes } = useStyles();

  return (
    <TextInput
      data-cy="addressInput"
      className={classes.address}
      label={label}
      placeholder={placeholder}
      value={form?.getInputProps(fieldName).value || ''}
      onChange={(event) => form?.setFieldValue(fieldName, event.target.value)}
      disabled={disabled}
      error={form?.getInputProps(fieldName).error}
    />
  );
};
