import { FC } from 'react';
import { IColumnData, IRowData } from '../types';
import { useStyles } from './styles';

interface ITrProps {
  columns: IColumnData[];
  row: IRowData;
  selection?: number[];
  pinnedRows?: number[];
  handleRowClick?: (id: number) => void;
}

export const Tr: FC<ITrProps> = ({ columns, row, selection, pinnedRows, handleRowClick }) => {
  const { classes, cx } = useStyles();

  const isSelected = !!selection?.includes(row.Id);
  const isPinned = !!pinnedRows?.includes(row.Id);

  const clickRow = (e: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => {
    const currentTarget = e.currentTarget;
    const target = e.target as HTMLElement;

    if (currentTarget.contains(target) && handleRowClick) {
      handleRowClick(Number(row?.Id));
    }
  };

  return (
    <tr
      data-id={row?.Id}
      onClick={clickRow}
      className={cx(classes.tr, {
        [classes.rowSelected]: isSelected,
        [classes.pinnedRow]: isPinned
      })}
    >
      {columns.map((col, colIndex) => {
        const currentValue = col.render && col.render(row, selection);

        return (
          <td
            key={colIndex}
            className={cx(classes.td, {
              [classes.withCheckbox]: Boolean(selection),
              [classes.hideColumn]: col.hideColumn
            })}
          >
            {currentValue}
          </td>
        );
      })}
    </tr>
  );
};
