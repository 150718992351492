import React, { FC, useCallback } from 'react';
import { Box, Text } from '@mantine/core';
import { useStyles } from './styles';
import useCopyToClipboard from '../../../hooks/useCopyToClipboard';
import { useTranslation } from 'react-i18next';
import OverflowTip from '@components/OverflowTip';

interface ITextFieldProps {
  title: string;
  description?: string | null;
  link?: boolean;
  leftSection?: React.ReactNode;
}

export const TextField: FC<ITextFieldProps> = ({ title, description, link, leftSection }) => {
  const { classes, cx } = useStyles();
  const { t } = useTranslation();
  const { copy } = useCopyToClipboard();

  const copyText = useCallback(async () => {
    await copy(description ?? '');
  }, [description]);

  return (
    <Box className={classes.container}>
      <Text className={classes.title}>{title}</Text>
      <Box className={classes.group}>
        {leftSection ? leftSection : null}
        <Box maw={300} onClick={copyText}>
          <OverflowTip
            data-text={description}
            className={cx(classes.description, {
              [classes.link]: description && link
            })}
          >
            {description ? description : t('noInformation')}
          </OverflowTip>
        </Box>
      </Box>
    </Box>
  );
};
