import { useState } from 'react';
import { Text, Flex, Box } from '@mantine/core';

import { IColumnData, IRowData } from '@components/simple-table/types';
import { IFilialQuota } from '@/entities/admin-app/quotas';

import { formatDataSizeUnits } from '@/lib/utils/format';
import { FilialQuotaAction } from '@/containers/pages/filials/edit/components/tabs/disk-space/components/table/filialQuotaAction';
import { useTranslation } from 'react-i18next';
import { IconInfinity } from '@tabler/icons';

interface IUseGetColumns {
  openFilialQuotaModal: () => void;
  isSaaS: boolean;
}

export const useGetFilialQuotaColumns = ({ openFilialQuotaModal, isSaaS }: IUseGetColumns) => {
  const { t } = useTranslation();

  const filialColumns: IColumnData[] = [
    {
      width: '33%',
      dataIndex: 'name',
      title: t('filials.diskSpace.table.name'),
      render: (item: IRowData) => <Text>{item?.Name || t('filials.diskSpace.commonQuota')}</Text>
    },
    {
      width: '20%',
      dataIndex: 'available',
      title: t('filials.diskSpace.table.available'),
      render: (item: IRowData) => (
        <Text>
          {!isSaaS && item?.QuotaSize === 0 ? (
            <Box pt="12px">
              <IconInfinity size={16} color="#667085" />
            </Box>
          ) : (
            formatDataSizeUnits(item?.QuotaSize)
          )}
        </Text>
      )
    },
    {
      width: '10%',
      dataIndex: 'used',
      title: t('filials.diskSpace.table.used'),
      render: (item: IRowData) => <Text>{formatDataSizeUnits(item?.UsedSize) || ''}</Text>
    },
    {
      width: 'auto',
      dataIndex: 'action',
      title: '',
      render: (item: IRowData) => (
        <Flex justify="flex-end">
          <FilialQuotaAction
            quota={item as IFilialQuota}
            openFilialQuotaModal={openFilialQuotaModal}
            isSaaS={isSaaS}
          />
        </Flex>
      )
    }
  ];
  const [filiaQuotaColumns, setFilialQuotaColumns] = useState<IColumnData[]>(filialColumns);

  return { filiaQuotaColumns, setFilialQuotaColumns };
};
