import { useCallback } from 'react';
import { showNotification } from '@mantine/notifications';
import { useActions } from '@hooks/redux/action';
import {
  useChangeCommonSettingsMutation,
  useLazyCommonSettingsQuery
} from '@/entities/admin-app/commonSettings/api';
import { ICommonSettings, ICommonSettingsEnum } from '@/entities/admin-app/commonSettings';

export const useSaveCommonSettings = () => {
  const [updateSettings] = useChangeCommonSettingsMutation();
  const { setCommonSettings } = useActions();
  const [getUpdatedValue, { isSuccess, data: updatedData }] = useLazyCommonSettingsQuery();
  return useCallback(async (Type: ICommonSettingsEnum, values: Partial<ICommonSettings>) => {
    try {
      const res = await updateSettings({
        Type,
        Data: values
      });
      if ('error' in res) {
        showNotification({
          title: `${(res as any)?.error?.data?.Response.Data}`,
          message: '',
          autoClose: true,
          color: 'red'
        });
      } else {
        showNotification({
          title: `Настройки успешно сохранены!`,
          message: '',
          autoClose: true,
          color: 'green'
        });
        getUpdatedValue();
        if (isSuccess && updatedData) {
          setCommonSettings(updatedData);
        }
      }
    } catch (err: any) {
      console.warn('Ошибка сохранения настроек', err);
    }
  }, []);
};
