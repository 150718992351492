import { ModalsProvider as ModalContext } from '@mantine/modals';
import { ModalSettings } from '@mantine/modals/lib/context';
import { FC } from 'react';
import { IComponentDefaultProps } from '../../../types';
import { ContextModal } from '../context-modal';
import { useStyles } from './styles';

export const ModalProvider: FC<IComponentDefaultProps> = ({ children }) => {
  const { classes } = useStyles();

  const modalProps: ModalSettings = {
    zIndex: 1400,
    // sx: { padding: '24px' },
    classNames: {
      body: classes.modal,
      header: classes.header
    }
  };

  return (
    <ModalContext modalProps={modalProps} modals={{ context: ContextModal }}>
      {children}
    </ModalContext>
  );
};
