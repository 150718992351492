import React, { FC } from 'react';
import { Avatar, Box, Text } from '@mantine/core';
import { useStyles } from './styles';
import { IFilialsInfoCommon } from '@/entities/admin-app/filials';
import { CompanyPlaceholder } from '@assets/icons';
import OverflowTip from '@components/OverflowTip';
interface IPhotoInfoProps {
  filialsInfoCommon?: IFilialsInfoCommon;
}

export const PhotoInfo: FC<IPhotoInfoProps> = ({ filialsInfoCommon }) => {
  const { classes } = useStyles();

  return (
    <Box className={classes.photo}>
      <Avatar color="blue" size={124} radius={62}>
        <CompanyPlaceholder size={60} />
      </Avatar>
      <Box className={classes.container}>
        <Box maw="300px">
          <OverflowTip className={classes.name}>{filialsInfoCommon?.Title || ''}</OverflowTip>
        </Box>

        <Text className={classes.email}>ID:{filialsInfoCommon?.Id}</Text>
      </Box>
    </Box>
  );
};
