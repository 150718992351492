import { MenuItemTypeEnum } from '@components/dropdown-menu';
import { UserStatusEnum } from '@/types/enums/user';
import { UsersActionTableEnum } from '@/types/enums/users';
import { IActionItem } from '../components/control/types';
import { useTranslation } from 'react-i18next';

export const useGetActionList = (): IActionItem[] => {
  const { t } = useTranslation();
  const subheaderList: IActionItem[] = [
    {
      label: t('users.actionList.emailConfirmation'),
      value: UsersActionTableEnum.sendActivationLinkAgain,
      menuItemType: MenuItemTypeEnum.item,
      list: []
    },
    {
      label: t('users.actionList.changeStatus'),
      value: UsersActionTableEnum.status,
      menuItemType: MenuItemTypeEnum.item,
      list: [
        {
          label: t('users.actionList.locked'),
          value: UserStatusEnum.Ban
        },
        {
          label: t('users.actionList.active'),
          value: UserStatusEnum.Active
        }
      ]
    },
    {
      label: t('users.actionList.writeLetter'),
      value: UsersActionTableEnum.writeLetter,
      menuItemType: MenuItemTypeEnum.item,
      list: []
    },
    {
      label: t('users.actionList.changeDepartment'),
      value: UsersActionTableEnum.filial,
      menuItemType: MenuItemTypeEnum.item,
      list: []
      // list: (filialList as IListItem[]) ?? []
    },
    {
      label: t('users.actionList.addRole'),
      value: UsersActionTableEnum.addRoles,
      menuItemType: MenuItemTypeEnum.item,
      list: []
    },

    {
      label: t('users.actionList.delete'),
      value: UsersActionTableEnum.delete,
      menuItemType: MenuItemTypeEnum.item,
      list: []
    }
    // {
    //   label: 'Сбросить пароль',
    //   value: UsersActionTableEnum.resetPassword,
    //   menuItemType: MenuItemTypeEnum.item,
    //   list: []
    // },
    // {
    //   label: 'Экспорт в CSV.',
    //   value: UsersActionTableEnum.export,
    //   menuItemType: MenuItemTypeEnum.item,
    //   list: [
    //     {
    //       label: 'Экспорт отображаемых полей',
    //       value: 'export'
    //     },
    //     {
    //       label: 'Экспорт всех полей',
    //       value: 'export-all'
    //     }
    //   ]
    // },
  ];

  return subheaderList;
};
