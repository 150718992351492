import { IPasswordSecurity } from '@/entities/admin-app/filials';
import { useTranslation } from 'react-i18next';
import { allowedPasswordCharactersString } from '@/utils/passwordUtils';

export const useGeneratePolicy = () => {
  const { t } = useTranslation();
  return (options?: IPasswordSecurity, maxLengthPassword?: number) => {
    const policyParts = [];

    if (options?.MinLength && options?.MinLength >= 0 && options?.MinLength <= 30) {
      const minMaxString = t('passwordReset.passwordPolicy.minMaxLength', {
        MinLength: options.MinLength,
        MaxLength: maxLengthPassword || 30
      });
      policyParts.push(minMaxString);
    }

    if (options?.UseCapitalLetters) {
      policyParts.push(t('passwordReset.passwordPolicy.useCapitalLetters'));
    }

    if (options?.UseLowLetter) {
      policyParts.push(t('passwordReset.passwordPolicy.useLowLetter'));
    }

    if (options?.UseDigital) {
      policyParts.push(t('passwordReset.passwordPolicy.digital'));
    }

    if (options?.UseSpecialCharacters) {
      policyParts.push(t('passwordReset.passwordPolicy.useSpecialCharacters'));
    }

    // if (options?.UseAllowedSpecialCharacters) {
    //   policyParts.push(
    //     t('passwordReset.passwordPolicy.allowedSpecialCharacters', {
    //       characters: allowedPasswordCharactersString
    //     })
    //   );
    // }
    if (policyParts.length === 0) {
      return t('passwordReset.passwordPolicy.noRequirements', {
        MinLength: options?.MinLength || 8,
        MaxLength: maxLengthPassword || 30
      });
    }

    return `${t('passwordReset.passwordPolicyString', { policy: policyParts.join(', ') })}${
      options?.UseAllowedSpecialCharacters
        ? t('passwordReset.passwordPolicy.allowedSpecialCharacters', {
            characters: allowedPasswordCharactersString
          })
        : ''
    }`;
  };
};
