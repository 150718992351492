import { FC, SyntheticEvent, useCallback } from 'react';

import { ActionIcon } from '@mantine/core';
import { IconDotsVertical } from '@tabler/icons';
import { useDisclosure, useMediaQuery } from '@mantine/hooks';

import { actionMenu, ActionQuotaMenuEnum } from './utils';

import { IQuota } from '@/entities/admin-app/quotas';

import { PopoverMenu } from '@components/popover-menu';

import { useDeleteQuotaModal } from '../../../hooks/useDeleteQuota';
import { useTranslation } from 'react-i18next';

interface IActionProps {
  quota?: IQuota;
  onEdit: (quota: IQuota) => void;
}

export const Action: FC<IActionProps> = ({ quota, onEdit }) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery('(max-width: 600px)');
  if (!quota) {
    return null;
  }

  const [opened, { close, open }] = useDisclosure(false);
  const { openDeleteQuotaModal } = useDeleteQuotaModal(quota);

  const onClickItem = useCallback(
    async (event: SyntheticEvent<HTMLDivElement>) => {
      close();

      event.stopPropagation();
      const { value } = event.currentTarget.dataset;

      switch (value) {
        case ActionQuotaMenuEnum.edit:
          onEdit(quota);
          break;
        case ActionQuotaMenuEnum.delete:
          openDeleteQuotaModal();
          break;
        default:
          break;
      }
    },
    [quota]
  );

  const handleContextMenu = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();
    close();
  };

  const handleClick = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.nativeEvent.stopPropagation();
    open();
  };

  return (
    <PopoverMenu
      opened={opened}
      onClickItem={onClickItem}
      width={292}
      list={actionMenu(quota?.id, t)}
      position={isMobile ? 'bottom-start' : 'left-start'}
      onClose={close}
    >
      <ActionIcon className="action-icon" onClick={handleClick} onContextMenu={handleContextMenu}>
        <IconDotsVertical color="#667085" data-action="action" />
      </ActionIcon>
    </PopoverMenu>
  );
};
