import { FC } from 'react';
import { Box, LoadingOverlay, Text } from '@mantine/core';
import { useStyles } from './styles';
import { useTranslation } from 'react-i18next';
import { ImageUploader } from '@components/image-uploader';
import { IUploadedFileResponse } from '@/entities/admin-app/commonSettings';
import { useImageUpload } from '@/containers/pages/commonSettings/hooks/useImageUpload';
interface ImageEditModalProps {
  onClose: (previousAvatar?: string) => void;
  id?: number;
  handleUpdateLogo: (data: IUploadedFileResponse) => void;
  dimensions: { width: number; height: number };
  allowedMimeType: string[];
}

export const ImageUploadModal: FC<ImageEditModalProps> = ({
  onClose,
  handleUpdateLogo,
  dimensions,
  allowedMimeType
}) => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { uploadLogoFile, isUploading } = useImageUpload();
  const handleUpload = async (image: string) => {
    const fileResponse = await uploadLogoFile(image);
    if (fileResponse) {
      handleUpdateLogo(fileResponse);
      onClose();
    }
  };

  return (
    <Box className={classes.root}>
      <Box className={classes.container}>
        <ImageUploader
          setLogoFile={handleUpload}
          maxDimensions={dimensions}
          allowedMimeType={allowedMimeType}
        />
        <Box className={classes.control}>
          <Text size="sm">{t('commonSettings.logoConditions', { ...dimensions })}</Text>
        </Box>
      </Box>
      <LoadingOverlay visible={isUploading} />
    </Box>
  );
};
