import { EditIcon } from '@/assets/icons';
import { ActionIcon, Box, Flex, Text } from '@mantine/core';
import { IconCircleX } from '@tabler/icons';
import { Dispatch, FC, SetStateAction } from 'react';
import { useStyles } from '../styles';
import { IDomain, IRecordDetails } from '@/entities/admin-app/email';

interface IDomainItem {
  domain: IDomain;
  setOpenPreviewModal: Dispatch<SetStateAction<boolean>>;
  setOpenDeleteModal: Dispatch<SetStateAction<boolean>>;
  setDomainDetails: Dispatch<SetStateAction<IDomain | IRecordDetails | undefined>>;
}

const DomainItem: FC<IDomainItem> = ({
  domain,
  setOpenPreviewModal,
  setOpenDeleteModal,
  setDomainDetails
}) => {
  const { classes, cx } = useStyles();

  const openDomainSettings = () => {
    setOpenPreviewModal(true);
    setDomainDetails(domain);
  };

  const openDeleteModal = () => {
    setOpenDeleteModal(true);
    setDomainDetails(domain);
  };

  return (
    <Flex w="100%" align="center" mb="15px">
      <Text
        className={cx(classes.domainText, {
          [classes.domainDisabled]: !domain.Id
        })}
        w="100%"
        maw="400px"
      >
        {domain.Domain}
      </Text>
      {domain.Id && (
        <Box display="flex">
          <ActionIcon size={24} mr={20} onClick={() => openDomainSettings()}>
            <EditIcon size={12} />
          </ActionIcon>
          <Box>
            <ActionIcon size={24} onClick={() => openDeleteModal()}>
              <IconCircleX color="#667085" />
            </ActionIcon>
          </Box>
        </Box>
      )}
    </Flex>
  );
};

export default DomainItem;
