import React, { FC, RefObject, SyntheticEvent, useCallback, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Box } from '@mantine/core';
import { FilialInfoPanelsEnum } from '@/types/enums/filials';
import {
  DriveInfoPanelsEnum,
  EmailInfoPanelsEnum,
  CalendarInfoPanelsEnum,
  DrawInfoPanelsEnum,
  ContactsInfoPanelsEnum
} from '@/types/enums/modules';
import { useStyles } from './styles';
import { useGetInfoSections } from './useGetInfoSections';

interface IInfoSectionProps {
  containerRef: RefObject<HTMLDivElement>;
}

export const InfoSection: FC<IInfoSectionProps> = ({ containerRef }) => {
  const location = useLocation();
  const { classes, cx } = useStyles();
  const currentPage = location.pathname.split('/').pop() || '';
  const {
    drawInfoSections,
    calendarInfoSections,
    contactsInfoSections,
    emailInfoSections,
    driveInfoSections,
    securityInfoSections
  } = useGetInfoSections();

  type sectionsType =
    | typeof driveInfoSections
    | typeof emailInfoSections
    | typeof calendarInfoSections
    | typeof drawInfoSections
    | typeof securityInfoSections
    | typeof contactsInfoSections;
  type sectionType =
    | DriveInfoPanelsEnum
    | EmailInfoPanelsEnum
    | CalendarInfoPanelsEnum
    | DrawInfoPanelsEnum
    | ContactsInfoPanelsEnum
    | FilialInfoPanelsEnum;

  const [sections, setSections] = useState<sectionsType | never[]>([]);
  const [activeSection, setActiveSection] = useState<sectionType | null>(null);
  useEffect(() => {
    switch (currentPage) {
      case 'security':
        setSections(securityInfoSections);
        break;

      case 'email':
        setSections(emailInfoSections);
        break;

      case 'calendar':
        setSections(calendarInfoSections);
        break;

      case 'draw':
        setSections(drawInfoSections);
        break;
      case 'contacts':
        setSections(contactsInfoSections);
        break;
      case 'drive':
        setSections(driveInfoSections);
        break;
      default:
        setSections([]);
        break;
    }
  }, [currentPage]);

  useEffect(() => {
    if (sections) {
      setActiveSection(sections[0]?.value || null);
    }
  }, [sections]);

  const handleClick = useCallback((event: SyntheticEvent<HTMLDivElement>) => {
    const { value } = event.currentTarget.dataset;
    if (!value) {
      return;
    }

    setActiveSection(value as sectionType);

    const targetElement = containerRef.current?.querySelector(`[data-section="${value}"]`);

    if (targetElement) {
      const headerHeight = 80;
      const scrollPosition =
        targetElement.getBoundingClientRect().top + window.scrollY - headerHeight;
      window.scrollTo({ top: scrollPosition, behavior: 'smooth' });
    }
  }, []);

  return (
    <Box className={classes.itemBox}>
      {sections.map((item) => {
        return (
          <Box
            className={cx(classes.item, {
              [classes.itemActive]: activeSection === item?.value
            })}
            onClick={handleClick}
            key={item?.value}
            data-value={item?.value}
          >
            {item?.label}
          </Box>
        );
      })}
    </Box>
  );
};
