import { Outlet } from 'react-router-dom';
import { Box } from '@mantine/core';
import { ErrorBoundary } from '@components/error-boundary';
import { Layout } from '../../layout';
import { useStyles } from './styles';
import { IRowData } from '@components/simple-table/types';
import { CreateRoles } from './components/create-item';
import { RolesSearch } from './components/search';
import RolesTable from './components/Table';

import { RolesInfoMain } from './components/main-info';
import { useState } from 'react';

export const Roles = () => {
  const { classes } = useStyles();
  const [setRowData] = useState<IRowData[]>([]);

  return (
    <ErrorBoundary componentTitle="Roles page">
      <Layout>
        <Box className={classes.container}>
          <Box className={classes.Wrapper}>
            <RolesSearch />
            <CreateRoles />
          </Box>
          <Box
            sx={() => ({
              width: '100%'
            })}
            className={classes.TableWrapper}
          >
            <RolesTable />
          </Box>
        </Box>
        <Outlet />
        <RolesInfoMain setRowData={setRowData as any} />
      </Layout>
    </ErrorBoundary>
  );
};
