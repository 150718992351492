import { ErrorsEnum } from '@/types/enums';

export interface IQueryEmailSend {
  Subject: string;
  Body: string;
  To: string[];
  Important?: boolean;
  IsAutogenerated?: boolean;
  IsReturnReceiptTo?: boolean;
  IsDispositionNotificationTo?: boolean;
}

export interface IUserEmailItem {
  Email: string;
  Id: number;
  Count: number;
}

export interface IEmailServer {
  Id: number;
  CustomerId?: number;
  Domains?: string | null;
  ImapConnectionType?: number | null;
  ImapPort?: number | null;
  ImapServer?: number | null;
  Integration?: {
    Ssh?: {
      Server: string | null;
      User: string | null;
      Password?: string | null;
      Port: number | null;
      PrivateKeyBase64: string | null;
      PublicKeyBase64: string | null;
    };
  } | null;
  Json?: string | null;
  Name?: string | null;
  PopConnectionType?: number | null;
  PopPort?: number | null;
  PopServer?: string | null;
  SmtpConnectionType?: number | null;
  SmtpPort?: number | null;
  SmtpServer?: string | null;
}

export interface IServerDomains {
  Domain: string;
  ServerId: number;
}

export interface IUserDomains {
  Domains: IServerDomains[];
}

export interface ILinkedEmail {
  Id: number;
  Email: string;
  Password: string | null;
  UserId: number;
  IsIntegrated?: boolean;
  EmailServer: IEmailServer | null;
}

export interface ICheckLinkedEmail {
  Email: string;
  CustomerId?: number | null;
}

export interface ICreateEmailResponse extends ILinkedEmail {
  Id: number;
}

export interface IChangeEmailPassword {
  EmailId: number;
  Password: string;
  NewPassword: string;
}

export interface IGetDomains {
  ServerId: number;
  CustomerId: number;
}

export interface IDomainsData {
  Domains: IDomain[];
}

export interface IDomain {
  Id: number | null;
  Domain: string;
}

export interface ILinkRecord {
  LinkRecord: string;
  Status: number;
}

export interface IDomainRecord {
  Domain: string;
  ServerId: number;
  CustomerId: number;
}

export interface IDomainRecordResponde {
  Domain: string;
  ServerId: number;
  CustomerId: number;
  Id: number;
}

export interface IRecordData {
  IsSussess: boolean;
  Name: string | null;
  Priority: number | null;
  Record: RecordName;
  Type: RecordType;
  Value: string;
}

export interface IRecordDetails {
  CustomerId: number;
  Domain: string;
  Id: number;
  Records: IRecordData[];
  ServerId: number;
}

export enum RecordName {
  MX = 1,
  SPF = 2,
  DKIM = 3
}

export enum RecordType {
  MX = 1,
  TXT = 2
}

export enum DomainResponse {
  SUCCESS = 1,
  NONEXISTS = 2,
  NONEXISTSRECORD = 3,
  EXISTS = 4,
  ERROR = 5
}

export interface ISendEmailItem {
  UserIds: number[];
  Subject: string;
  Body: string;
}

export interface ISendEmailResponse {
  ErrorCode: ErrorsEnum;
}
export interface IVerifyEmailServersResponse {
  Customers: IGetVerifyEmailServers[];
}

export interface IGetVerifyEmailServers {
  CustomerId: number;
  HasSettings: boolean;
}
