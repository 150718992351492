import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IQueryTable } from '../../../types/api';
import { IGroupsInitialFilters } from '../../../types/enums/groups';
import { IGroupsData, IGroupTableData } from './types';

interface IUsers {
  groups: IGroupTableData[];
  total: number;
  filters: IQueryTable<IGroupsInitialFilters>;
  selectedGroupId: number;
  selected: number[];
}

const initialState: IUsers = {
  groups: [],
  total: 0,
  filters: {
    Count: 20,
    Offset: 0,
    SortOrder: 0,
    SortField: 0,
    Filters: {},
    Query: ''
  },
  selectedGroupId: 0,
  selected: []
};

export const groupsSlice = createSlice({
  name: 'groups',
  initialState,
  reducers: {
    setGroups(state, action: PayloadAction<IGroupsData>) {
      state.groups = action.payload.Roles;
      state.total = action.payload.TotalCount;
    },
    setGroupsTableFilters(state, action: PayloadAction<IQueryTable<IGroupsInitialFilters>>) {
      state.filters = action.payload;
    },
    setSelectedGroupId(state, action: PayloadAction<number>) {
      state.selectedGroupId = action.payload;
    },
    setSelectedGroupsIds(state, action: PayloadAction<number>) {
      state.selected.includes(action.payload)
        ? (state.selected = state.selected.filter((state) => state !== action.payload))
        : (state.selected = [...state.selected, action.payload]);
    },
    setSelectedAllGroups(state, action: PayloadAction<number[]>) {
      state.selected = state.selected.length === action.payload.length ? [] : action.payload;
    },
    clearGroupsSelected(state) {
      state.selected = [];
    }
  }
});

export const groupsActions = groupsSlice.actions;
export const groupsReducer = groupsSlice.reducer;
