import { useCloseseSsionsMutation } from '@/entities/admin-app/user/api';
import { useTranslation } from 'react-i18next';
import { universalNotification } from '@/lib/utils/notification';

export const useTerminateSessions = () => {
  const { t } = useTranslation();
  const [fetchCloseSessions, { isLoading, isSuccess, isError, error }] = useCloseseSsionsMutation();

  const closeSessions = async (
    options: { ids?: number[]; userId?: number | null },
    fn?: () => void
  ) => {
    const { ids, userId } = options;

    const successText = t(
      `profile.${!ids ? 'allConnectionsTerminatedSuccess' : 'oneConnectionTerminatedSuccess'}`
    );
    try {
      const requestData = { Ids: ids, UserId: userId };
      const res: any = await fetchCloseSessions(requestData);

      universalNotification({
        isSuccess,
        isError,
        error,
        successTitle: successText
      });
      if ('error' in res) {
        universalNotification({
          error: `${(res as any)?.error}`,
          isError: true,
          isSuccess: false
        });
      } else {
        fn && fn();
        universalNotification({
          isSuccess: true,
          isError: false,
          error: null,
          successTitle: successText
        });
      }
    } catch (err) {
      console.warn('useTerminateSessions ERROR', err);
    }
  };

  return { closeSessions, isLoading, isSuccess, isError };
};
