import React, { createContext, Dispatch, FC, SetStateAction } from 'react';
import { HomePanel } from './home';
import { MoreInfoPanel } from './more-info';

import { PanelsEnum } from '@/types/enums/roles';
import { useGetRolesInfoCommonQuery } from '@/entities/admin-app/roles/api';
import { IRowData } from '@components/simple-table/types';
import { IRolesInfoCommon } from '@/entities/admin-app/roles';
import { useAppSelector } from '@/hooks/redux/redux';

export interface IRolesInfoMainContext {
  selectedRolesId: number;
  setRowData: Dispatch<SetStateAction<IRowData[]>>;
  rolesInfoCommon?: IRolesInfoCommon;
}

export const RolesInfoMainContext = createContext<IRolesInfoMainContext>(
  {} as IRolesInfoMainContext
);

export interface IPanelsProps {
  selectedRolesId: number;
  setRowData: Dispatch<SetStateAction<IRowData[]>>;
}

export const Panels: FC<IPanelsProps> = ({ selectedRolesId, setRowData }) => {
  const { data } = useGetRolesInfoCommonQuery(selectedRolesId);

  const { selectedPanel } = useAppSelector((state) => state.roles);

  const initialValues: IRolesInfoMainContext = {
    selectedRolesId,
    setRowData,
    rolesInfoCommon: data
  };

  return (
    <RolesInfoMainContext.Provider value={initialValues}>
      {selectedPanel === PanelsEnum.generalInformation && <HomePanel />}
      {selectedPanel === PanelsEnum.editInformation && <MoreInfoPanel />}
    </RolesInfoMainContext.Provider>
  );
};
