import { FC, useEffect, useState } from 'react';
import { Box } from '@mantine/core';
import { Modal } from '..';
import { IUsersData, IUserTableData } from '@/entities/admin-app/users';
import { Roles } from './roles';
import { useStyles } from './styles';
import { Users } from './users';
import { IQueryTable } from '@/types/api';
import { ControlSection } from './control-select-user';
import { IUsersInitialFilters } from '@/types/enums/users';
import { IFilialsInfoCommon } from '@/entities/admin-app/filials';

export interface IControlSectionProps {
  confirmProps: {
    btnName: string;
    fn: () => void;
  };
  cancelProps: {
    btnName: string;
    fn: () => void;
  };
}

interface ISelectUserPropsSingle {
  isOpened: boolean;
  title: string;
  onClose: () => void;
  controlSection?: boolean;
  userId?: number;
  controlSectionProps?: IControlSectionProps;
  multipleUsers: false;
  setSelectedUsers: (value: IUserTableData) => void;
  usersList?: IUserTableData[];
  filial?: IFilialsInfoCommon | null;
  SkipUsersIds?: number[];
}
interface ISelectUserPropsMultiple {
  isOpened: boolean;
  title: string;
  onClose: () => void;
  controlSection?: boolean;
  userId?: number;
  controlSectionProps?: IControlSectionProps;
  multipleUsers: true;
  setSelectedUsers: (value: number[]) => void;
  usersList?: IUserTableData[];
  filial?: IFilialsInfoCommon | null;
  SkipUsersIds?: number[];
}

type ISelectUserModalProps = ISelectUserPropsMultiple | ISelectUserPropsSingle;

export const SelectUserModal: FC<ISelectUserModalProps> = ({
  isOpened,
  title,
  onClose,
  setSelectedUsers,
  userId,
  controlSection,
  multipleUsers,
  controlSectionProps,
  usersList,
  filial,
  SkipUsersIds
}) => {
  const { classes } = useStyles();

  const [users, setUsers] = useState<IUsersData>({} as IUsersData);
  const [filters, setFilters] = useState<IQueryTable<IUsersInitialFilters>>(
    {} as IQueryTable<IUsersInitialFilters>
  );
  const [totalCount, setTotalCount] = useState<number>(0);

  useEffect(() => {
    if (users.TotalCount && totalCount === 0) {
      setTotalCount(users.TotalCount);
    }
  }, [users]);

  return (
    <Modal size="630px" opened={isOpened} title={title} onClose={onClose}>
      <Box className={classes.root}>
        <Roles totalCount={totalCount} filters={filters} setFilters={setFilters} filial={filial} />
        {multipleUsers ? (
          <Users
            setSelectedUsers={setSelectedUsers}
            userId={userId}
            users={users}
            filters={filters}
            setFilters={setFilters}
            setUsers={setUsers}
            multiple={multipleUsers}
            usersList={usersList}
            filial={filial}
            SkipIds={SkipUsersIds}
          />
        ) : (
          <Users
            setSelectedUsers={setSelectedUsers}
            userId={userId}
            users={users}
            filters={filters}
            setFilters={setFilters}
            setUsers={setUsers}
            multiple={multipleUsers}
            filial={filial}
            SkipIds={SkipUsersIds}
          />
        )}
      </Box>
      {controlSection && (
        <ControlSection
          userId={userId}
          handleClose={onClose}
          controlSectionProps={controlSectionProps}
        />
      )}
    </Modal>
  );
};
