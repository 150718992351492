import { Api } from '@reduxjs/toolkit/dist/query';
import { store } from '@/store';
import { IErrorResponse } from '@/types/api';

import { directoriesApi } from '@/entities/admin-app/directories/api';
import { emailApi } from '@/entities/admin-app/email/api';
import { groupsApi } from '@/entities/admin-app/groups/api';
import { userApi } from '@/entities/admin-app/user/api';
import { usersApi } from '@/entities/admin-app/users/api';
import { rolesApi } from '@/entities/admin-app/roles/api';
import { quotasApi } from '@/entities/admin-app/quotas/api';
import { modulesApi } from '@/entities/admin-app/modules/api';
import { statisticsApi } from '@/entities/admin-app/statistics/api';
import { filialsApi } from '@/entities/admin-app/filials/api';

export const clearApiCacheByTags = (api: Api<any, any, any, any, any>, tags: string[]) =>
  store.dispatch(api.util.invalidateTags(tags));

export interface ApiErrorResponse {
  status: number;
  data: IErrorResponse;
}

export function isApiResponse(error: unknown): error is ApiErrorResponse {
  return typeof error === 'object' && error != null && 'data' in error;
}

export const resetAllApiState = () => {
  [
    directoriesApi,
    emailApi,
    groupsApi,
    userApi,
    usersApi,
    rolesApi,
    quotasApi,
    modulesApi,
    statisticsApi,
    filialsApi
  ].forEach((api) => {
    store.dispatch(api.util.resetApiState());
  });
};

export function getCookieByName(cookieName: string): string | null {
  try {
    const cookies: Record<string, string> = document.cookie
      .split('; ')
      .reduce((acc: Record<string, string>, pair) => {
        const [key, value] = pair.split('=');
        acc[key.trim()] = decodeURIComponent(value);
        return acc;
      }, {});
    return cookies[cookieName] || null;
  } catch (error) {
    console.error('Error retrieving cookie:', error);
    return null;
  }
}
