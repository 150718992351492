import { FC, useCallback, useContext, useMemo, useState } from 'react';
import { Box, Text } from '@mantine/core';
import { HeaderPanel } from '../../components';
import { useStyles } from './styles';
import { InfoIcon } from '@assets/icons';
import { ConnectionItem } from './connection-item';
import { PanelsEnum } from '@/types/enums/user';
import { UserInfoMainContext } from '..';
import { IActiveConnection } from '@/entities/admin-app/user';
import { showNotification } from '@mantine/notifications';
import { useAppSelector } from '@hooks/redux/redux';
import { useTerminateSessions } from '../../../../hooks/useTerminateSessions';
import { useTranslation } from 'react-i18next';

export const ActiveConnectionsPanel: FC = () => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { userInfoCommon } = useContext(UserInfoMainContext);
  const { User } = useAppSelector((state) => state.userInfo);
  const isMyProfilePage = userInfoCommon?.Id === User?.Id || false;

  const [list, setList] = useState<IActiveConnection[]>(
    userInfoCommon?.Connections.length ? userInfoCommon?.Connections : []
  );

  const currentConnectionItem = list.find((item) => item.IsOwner);

  const allConnectionIds = useMemo(() => list.map((item) => item.Id), [list]);

  const { closeSessions } = useTerminateSessions();

  const terminateConnection = useCallback(
    async (event: React.SyntheticEvent<HTMLButtonElement>) => {
      const id = Number(event.currentTarget.dataset.id) ?? null;

      if (currentConnectionItem && currentConnectionItem.Id === id) {
        showNotification({
          title: t('profile.cantDisconnectCurrentConnection'),
          message: '',
          autoClose: true,
          color: 'red'
        });
      } else {
        const updateList = () => {
          setList((prevState) => prevState.filter((item: IActiveConnection) => item.Id !== id));
        };
        await closeSessions({ ids: [id] }, updateList);
      }
    },
    []
  );

  const terminateAllConnections = useCallback(async () => {
    const filterConnectionsIds = allConnectionIds?.filter(
      (id: number) => id !== currentConnectionItem?.Id
    );
    if (filterConnectionsIds.length > 0) {
      const updateList = () => {
        setList((prevState) =>
          prevState.filter((item: IActiveConnection) => item.Id === currentConnectionItem?.Id)
        );
      };
      await closeSessions({ userId: userInfoCommon?.Id || null }, updateList);
    }
  }, [allConnectionIds]);

  return (
    <>
      <HeaderPanel
        title={t('profile.activeConnections')}
        activePanel={PanelsEnum.activeConnections}
      />
      <Box className={classes.content}>
        {list.length ? (
          <>
            <Box className={classes.header}>
              <Text className={classes.description} onClick={terminateAllConnections}>
                {isMyProfilePage
                  ? list.length > 1
                    ? t('profile.terminateMyConnections')
                    : null
                  : t('profile.terminateAllConnections')}
              </Text>
              {isMyProfilePage && list.length === 1 ? null : <InfoIcon />}
            </Box>
            {list.map((item) => {
              return (
                <ConnectionItem
                  key={item.Id}
                  {...item}
                  isCurrent={item.IsOwner}
                  terminateConnection={terminateConnection}
                />
              );
            })}
          </>
        ) : (
          <Box className={classes.emptyData}>{t('profile.noActiveConnections')}</Box>
        )}
      </Box>
    </>
  );
};
