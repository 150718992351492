import { IGlobalSettings, IUser } from '@/entities/admin-app/auth';
import { IRole } from '@/entities/admin-app/directories';
import { RoleTypesEnum } from '@/types/enums/user';
import { isFeature } from '@utils/feature';

export const isAdmin = (user?: IUser) => {
  const adminRoles = [RoleTypesEnum.Admin, RoleTypesEnum.SuperUser, RoleTypesEnum.Support];
  return !!user?.Roles.some((role: IRole) => role?.Type && adminRoles.includes(role?.Type));
};
export const isSuperAdmin = (user?: IUser) => {
  const superAdminRoles = [RoleTypesEnum.SuperUser];
  return !!user?.Roles.some((role: IRole) => role?.Type && superAdminRoles.includes(role?.Type));
};

export const parseUrlParameters = (): Record<string, string> => {
  const parameters: Record<string, string> = {};
  window.location.search.replace(
    new RegExp('([^?=&]+)(=([^&]*))?', 'g'),
    function (_match, key, _equal, value) {
      parameters[key] = value;
      return '';
    }
  );
  return parameters;
};
export const urlParams = parseUrlParameters();

export const isSaaS = (settings?: IGlobalSettings): boolean => {
  if (isFeature() && urlParams.appType) {
    return urlParams.appType === 'saas';
  }
  return settings?.AppType === 'saas';
};

export const isCrypt = (settings?: IGlobalSettings): boolean => {
  return Boolean(settings?.IsCrypt);
};

export const canEditRole = (Type?: RoleTypesEnum | null): boolean => {
  return (
    Type !== RoleTypesEnum.Admin &&
    Type !== RoleTypesEnum.SuperUser &&
    Type !== RoleTypesEnum.All &&
    Type !== RoleTypesEnum.Support
  );
};
