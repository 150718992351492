import { FC } from 'react';
import { SidebarMenu } from '@/components/SidebarMenu';
import { getCommonSettingsRoutes } from '@/containers/pages/commonSettings/utils';
import { IGlobalSettings } from '@/entities/admin-app/auth';
import { useGetNavMenuItems } from './useGetNavMenuItems';
import { useTranslation } from 'react-i18next';

interface IMenuProps {
  setIsOpen: (isOpen: boolean) => void;
  Settings?: IGlobalSettings;
}

export const NavMenu: FC<IMenuProps> = ({ setIsOpen, Settings }) => {
  const { t } = useTranslation();
  const commonSettingsMenuItems = useGetNavMenuItems({ Settings });
  const linksBlocks = [
    {
      label: t('common'),
      pages: commonSettingsMenuItems
    }
  ];
  return (
    <SidebarMenu
      routes={getCommonSettingsRoutes()}
      setIsOpen={setIsOpen}
      linksBlocks={linksBlocks}
    />
  );
};
