import React, { FC, SyntheticEvent, useCallback, useContext } from 'react';
import { Box, Text } from '@mantine/core';
import { useStyles } from './styles';
import { IListItem } from '@/types';
import { WarningCircleIcon, ServerIcon, DatabaseIcon } from '@/assets/icons';
import { PanelsEnum } from '@/types/enums/user';
import { UserInfoMainContext } from '../../panels';
import { useTranslation } from 'react-i18next';
import { generateDataCyValue } from '@/utils/kebabToCamel';

export const MoreActions: FC = () => {
  const { classes } = useStyles();
  const { setActivePanel } = useContext(UserInfoMainContext);
  const { t } = useTranslation();

  const list: IListItem[] = [
    {
      label: t('profile.additionalInfo'),
      icon: <WarningCircleIcon stroke="#667085" />,
      value: PanelsEnum.additionalInformation
    },
    // {
    //   label: 'Список групп',
    //   icon: <GroupsIcon stroke="#667085" />,
    //   value: PanelsEnum.groups
    // },
    // {
    //   label: 'Заметки',
    //   icon: <NotesIcon />,
    //   value: PanelsEnum.notes
    // },
    // {
    //   label: 'Подписки',
    //   icon: <MailIcon />,
    //   value: PanelsEnum.subscriptions
    // },
    {
      label: t('profile.activeConnections'),
      icon: <ServerIcon />,
      value: PanelsEnum.activeConnections
    },
    {
      label: t('profile.savingQuota'),
      icon: <DatabaseIcon />,
      value: PanelsEnum.dataStorageQuota
    }
  ];

  const handleClick = useCallback((event: SyntheticEvent<HTMLDivElement>) => {
    const value = (event.currentTarget.dataset.value as PanelsEnum) ?? '';

    setActivePanel(value);
  }, []);

  return (
    <Box className={classes.container}>
      {list.map((item: IListItem, index: number) => {
        return (
          <Box
            key={index}
            data-value={item.value}
            onClick={handleClick}
            className={classes.group}
            data-cy={generateDataCyValue(item)}
          >
            {item.icon}
            <Text className={classes.text}>{item.label}</Text>
          </Box>
        );
      })}
    </Box>
  );
};
