import { encryptContent, arrayBufferToBase64 } from '@/lib/utils/encryption';
import { useLazyCryptQuery } from '@/entities/admin-app/auth/api';

export const useFetchToCrypt = () => {
  const [fetchCrypt] = useLazyCryptQuery();

  return async (
    message: string,
    succesResponse: (encryptedBase64: string) => void,
    errorResponse?: (error: Error) => void
  ) => {
    const { data, error } = await fetchCrypt();
    const isError = error as { status: number } | undefined;

    if (!isError && data) {
      const encryptedBuf = await encryptContent(data.Jwk, message);
      const encryptedBase64 = arrayBufferToBase64(encryptedBuf);

      succesResponse(encryptedBase64);
    } else {
      errorResponse && errorResponse(new Error('Error'));
    }
  };
};
