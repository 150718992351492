import { z } from 'zod';
import { TFunction } from 'i18next';

const isValidIPv4 = (value: string) => {
  return z.string().ip({ version: 'v4' }).safeParse(value).success;
};

export const validateSchemeIpSecurity = (t: TFunction) =>
  z.object({
    addresses: z.array(
      z.object({
        address: z
          .string()
          .refine(
            (value) => {
              if (value.includes('-')) {
                const indexOfSeparator = value.indexOf('-');
                const startIp = value.substring(0, indexOfSeparator);
                const endIp = value.substring(indexOfSeparator + 1);
                return isValidIPv4(startIp) && isValidIPv4(endIp);
              }
              return true;
            },
            { message: t('validation.ipAddressRange') }
          )
          .refine(
            (value) => {
              if (value.includes('-')) {
                return true;
              }
              return isValidIPv4(value);
            },
            { message: t('validation.ipAddress') }
          )
      })
    )
  });
