import { FC, useContext, useEffect } from 'react';
import { EditUserInfoContext } from '../../../index';
import { Button, LoadingOverlay } from '@mantine/core';
import { ValidateTextInput } from '@/containers/pages/user/components/validateFields/validateTextInput';
import { FormErrors, useForm } from '@mantine/form';
import { getValidateErrors } from '@/lib/utils/validation';
import { ILoginCredentials } from '@/entities/admin-app/user';
import { validateLoginScheme } from '@/containers/pages/user/schemes/validate';
import { ProfileUpdateTypeEnum, ProfileSectionEnum } from '@/types/enums/users';
import { useUpdateInfo } from '@/hooks/users/useUpdateUser';
import { useTranslation } from 'react-i18next';

const LoginCredentials: FC = () => {
  const { t } = useTranslation();
  const { currentId, userInfoCommon } = useContext(EditUserInfoContext);
  const { Credentials } = userInfoCommon;

  const { saveUpdate, isLoading } = useUpdateInfo();

  const loginCredentialsForm = useForm<ILoginCredentials>({
    initialValues: {
      Login: ''
    },
    validateInputOnChange: true,
    validate: (values: ILoginCredentials): FormErrors =>
      getValidateErrors(validateLoginScheme(t), values)
  });

  const errors = loginCredentialsForm.errors;
  const isDirtyForm = loginCredentialsForm.isDirty();
  const isDisabled = Object.keys(errors).length !== 0 || !isDirtyForm;

  const fromData = {
    Login: Credentials?.Login || ''
  };

  useEffect(() => {
    loginCredentialsForm.setValues(fromData);
    loginCredentialsForm.resetDirty(fromData);
  }, [Credentials]);

  const handleSubmit = async (values: ILoginCredentials) => {
    await saveUpdate({
      Type: ProfileUpdateTypeEnum.Credentials,
      Data: { [ProfileSectionEnum.Credentials]: values, Id: currentId }
    });
    loginCredentialsForm.setValues(fromData);
    loginCredentialsForm.resetDirty(fromData);
  };

  return (
    <form onSubmit={loginCredentialsForm.onSubmit(handleSubmit)}>
      <ValidateTextInput
        fieldName="Login"
        label={t('profile.login')}
        form={loginCredentialsForm}
        isRequired={true}
      />

      <Button
        type="submit"
        size="sm"
        radius={8}
        disabled={isDisabled}
        mt="md"
        style={{ padding: '0 20px' }}
      >
        {t('profile.save')}
      </Button>
      <LoadingOverlay visible={isLoading} />
    </form>
  );
};

export default LoginCredentials;
