import { setToEndOFDate } from '@/containers/pages/filials/utils';
import { IFilterConditionItem } from '@/types/api';
import { IFiltersEnumType } from '@/types/enums';

export const convertToFilterConditions = (
  activeFilters: Record<IFiltersEnumType, number[] | Date[]>
): IFilterConditionItem[] => {
  const filterObjects: IFilterConditionItem[] = [];

  for (const [key, value] of Object.entries(activeFilters)) {
    const filterType = +key;
    const filterObject: IFilterConditionItem = {
      Type: filterType
    };

    if (filterType === IFiltersEnumType.DateOfCreateFilter) {
      const [from, to] = value as Date[];
      filterObject.DateRange = {
        From: from?.toISOString(),
        To: setToEndOFDate(new Date(to))
      };
    } else {
      filterObject.NumValues = value as number[];
    }

    filterObjects.push(filterObject);
  }

  return filterObjects;
};
