import { IListItem } from '@/types';

export function kebabToCamelCase(str: string): string {
  return str.replace(/-([a-z])/g, function (_, letter) {
    return letter.toUpperCase();
  });
}

export function generateDataCyValue(item: IListItem) {
  if (item && typeof item.value == 'string') {
    return kebabToCamelCase(item.value);
  }
}
