import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { Modal } from '..';
import { IGroup } from '@/entities/admin-app/user';
import { IListItem } from '@/types';
import { AddNewGroup } from './add-new-group';
import { SelectGroup } from './select-group';
import { SetParentGroup } from './set-parent-group';

interface ISelectGroupModalProps {
  isOpened: boolean;
  title: string;
  onClose: () => void;
  setSelectedGroup: (value: IListItem) => void;
  selectedGroup: IListItem;
  setGroups: (data: IGroup[]) => void | Dispatch<SetStateAction<IGroup[]>>;
  groups: IGroup[];
}

export enum SelectGroupEnum {
  selectGroup = 'select-group',
  addNewGroup = 'add-new-group',
  setParentGroup = 'set-parent-group'
}

export const SelectGroupModal: FC<ISelectGroupModalProps> = ({
  isOpened,
  title,
  onClose,
  setSelectedGroup,
  selectedGroup,
  setGroups,
  groups
}) => {
  const [activeModal, setActiveModal] = useState<string>(SelectGroupEnum.selectGroup);
  const [activeTitle, setActiveTitle] = useState<string>(title);
  const [nameGroup, setNameGroup] = useState<string>('');

  const handleClose = () => {
    onClose();
    setActiveModal(SelectGroupEnum.selectGroup);
    setActiveTitle(title);
  };

  useEffect(() => {
    switch (activeModal) {
      case SelectGroupEnum.selectGroup:
        setActiveTitle(title);
        break;
      case SelectGroupEnum.addNewGroup:
        setActiveTitle('Создать новую группу');
        break;
      case SelectGroupEnum.setParentGroup:
        setActiveTitle('Выбрать родительскую группу');
        break;
      default:
        break;
    }
  }, [activeModal]);

  return (
    <Modal size="450px" opened={isOpened} title={activeTitle} onClose={handleClose}>
      {activeModal === SelectGroupEnum.selectGroup && (
        <SelectGroup
          setActiveModal={setActiveModal}
          setGroups={setGroups}
          groups={groups}
          handleClose={handleClose}
        />
      )}
      {activeModal === SelectGroupEnum.addNewGroup && (
        <AddNewGroup
          setActiveModal={setActiveModal}
          selectedGroup={selectedGroup}
          setSelectedGroup={setSelectedGroup}
          nameGroup={nameGroup}
          setNameGroup={setNameGroup}
        />
      )}
      {activeModal === SelectGroupEnum.setParentGroup && (
        <SetParentGroup
          setActiveModal={setActiveModal}
          setSelectedGroup={setSelectedGroup}
          selectedGroup={selectedGroup}
        />
      )}
    </Modal>
  );
};
