import { FC } from 'react';
import { Modal } from '..';
import { SelectGroup } from './select-group';
import { IFilialsInfoCommon } from '@/entities/admin-app/filials';
interface Group {
  label: string;
  value: number;
  checked: boolean;
  counter: number;
}
interface ISelectGroupSimpleModalProps {
  isOpened: boolean;
  title: string;
  onClose: () => void;
  setGroupId: (id: number) => void;
  setGroup?: (group: Group) => void;
  filial?: IFilialsInfoCommon | null;
  SkipIds?: number[];
}

export const SelectGroupSimpleModal: FC<ISelectGroupSimpleModalProps> = ({
  isOpened,
  title,
  onClose,
  setGroupId,
  setGroup,
  filial,
  SkipIds
}) => {
  const handleClose = () => {
    onClose();
  };

  return (
    <Modal size="450px" opened={isOpened} title={title} onClose={handleClose}>
      <SelectGroup
        setGroupId={setGroupId}
        setGroup={setGroup}
        handleClose={handleClose}
        filial={filial}
        SkipIds={SkipIds}
      />
    </Modal>
  );
};
