import { FC, useContext, useEffect } from 'react';
import { EditUserInfoContext } from '../../../index';
import { Button, LoadingOverlay } from '@mantine/core';
import { ValidateTextInput } from '@/containers/pages/user/components/validateFields/validateTextInput';
import { FormErrors, useForm } from '@mantine/form';
import { getValidateErrors } from '@/lib/utils/validation';
import { IEmailCredentials } from '@/entities/admin-app/user';
import { ProfileUpdateTypeEnum, ProfileSectionEnum } from '@/types/enums/users';
import { useUpdateInfo } from '@hooks/users/useUpdateUser';
import { isSaaS } from '@/lib/utils/access';
import { useAppSelector } from '@hooks/redux/redux';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';
import { isSaasScheme, noSaasScheme } from '@/containers/pages/user/schemes/validate';

const EmailCredentials: FC = () => {
  const { t } = useTranslation();
  const { currentId, userInfoCommon } = useContext(EditUserInfoContext);
  const { Credentials, Customer } = userInfoCommon;
  const { Settings } = useAppSelector((state) => state.userInfo);

  const { saveUpdate, isLoading } = useUpdateInfo();

  const validateScheme = z.union([isSaasScheme(t), noSaasScheme(t)]);

  const emailCredentialsForm = useForm<IEmailCredentials>({
    initialValues: {
      isSaaS: false,
      Email: ''
    },
    validateInputOnChange: true,
    validate: (values: IEmailCredentials): FormErrors => getValidateErrors(validateScheme, values)
  });

  const errors = emailCredentialsForm.errors;
  const isDirtyForm = emailCredentialsForm.isDirty();
  const isDisabled = Object.keys(errors).length !== 0 || !isDirtyForm;

  const fromData = {
    isSaaS: isSaaS(Settings),
    Email: Credentials?.Email || '',
    IsConfirmed: Credentials?.IsConfirmed
  };

  useEffect(() => {
    emailCredentialsForm.setValues(fromData);
    emailCredentialsForm.resetDirty(fromData);
  }, [Credentials.Email, Credentials.IsConfirmed]);

  const handleSubmit = async (values: IEmailCredentials) => {
    await saveUpdate({
      Type: ProfileUpdateTypeEnum.CredentialEmail,
      Data: {
        [ProfileSectionEnum.Credentials]: { Email: values.Email, IsConfirmed: values.IsConfirmed },
        Id: currentId
      }
    });
    emailCredentialsForm.setValues(fromData);
    emailCredentialsForm.resetDirty(fromData);
  };

  return (
    <form onSubmit={emailCredentialsForm.onSubmit(handleSubmit)}>
      <ValidateTextInput
        fieldName="Email"
        isSaasEmail={isSaaS(Settings)}
        currentId={currentId}
        label={t('profile.email')}
        form={emailCredentialsForm}
        isConfirmed={emailCredentialsForm?.values?.IsConfirmed}
        userId={currentId}
        customerId={Customer?.Id}
        withAsterisk={isSaaS(Settings)}
      />

      <Button
        type="submit"
        size="sm"
        radius={8}
        disabled={isDisabled}
        mt="md"
        style={{ padding: '0 20px' }}
      >
        {t('profile.save')}
      </Button>
      <LoadingOverlay visible={isLoading} />
    </form>
  );
};

export default EmailCredentials;
