import { IPasswordSecurity } from '@/entities/admin-app/filials';

export const defaultMailServerPasswordOptions: Readonly<IPasswordSecurity> = {
  MinLength: 8,
  UseDigital: true,
  UseCapitalLetters: true,
  UseLowLetter: true,
  UseAllowedSpecialCharacters: true,
  UseSpecialCharacters: false
};
