import { FC, useContext } from 'react';
import { Box, Progress, Text } from '@mantine/core';

import { HeaderPanel, TextField } from '../../components';

import { formatByteToGb, formatDataSizeUnits } from '@/lib/utils/format';
import { calculatePercentage } from '@/lib/utils';

import { UserInfoMainContext } from '..';
import { useTranslation } from 'react-i18next';
import { useStyles } from './styles';

export const DataStorageQuotaPanel: FC = () => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { userInfoCommon } = useContext(UserInfoMainContext);

  const { Percentage, QuotaSize, UsingQuotaSize } = userInfoCommon?.StorageData || {};

  const FillPercentTmp = Percentage ? Percentage : calculatePercentage(QuotaSize, UsingQuotaSize);
  return (
    <>
      <HeaderPanel title={t('profile.savingQuota')} rightIcon={false} />
      <Box className={classes.content}>
        <Box className={classes.group}>
          <Text className={classes.progressText}>
            {t('profile.filledSpacePercent', { FillPercentTmp: FillPercentTmp })}
          </Text>
          <Box className={classes.progressContainer}>
            <Progress className={classes.progress} value={FillPercentTmp} />
          </Box>
        </Box>

        <TextField title={t('profile.quotaDetails')} description={userInfoCommon?.Quota?.Name} />
        <TextField title={t('quota.size')} description={formatByteToGb(QuotaSize)} />
        <TextField title={t('quota.used')} description={formatDataSizeUnits(UsingQuotaSize)} />
      </Box>
    </>
  );
};
