import { FC } from 'react';
import UserFilial from './filial';
import UserPositions from './positions';
import UserRole from './roles';

const FilialData: FC = () => {
  return (
    <>
      <UserFilial />
      <UserPositions />
      <UserRole />
    </>
  );
};

export default FilialData;
