import { z } from 'zod';
import { useTranslation } from 'react-i18next';

export const useGetLdapSchemes = () => {
  const { t } = useTranslation();
  const portSchema = z
    .number({
      required_error: t('filials.authorization.addPort'),
      invalid_type_error: t('filials.authorization.numberPort')
    })
    .positive({ message: t('errors.positive') })
    .lt(65536, { message: t('errors.maxValue') });

  const noAuthSchema = z.object({
    useLDAP: z.literal('false'),
    server: z.string(),
    login: z.string(),
    password: z.string(),
    mapping: z.object({}),
    GroupMapping: z.object({})
  });

  const authSchema = z.object({
    useLDAP: z.literal('true'),
    server: z.string().min(1, { message: t('errors.enterServer') }),
    login: z.string().min(1, { message: t('servers.addLogin') }),
    password: z.string().min(1, { message: t('errors.enterPassword') }),
    UseSsl: z.boolean().optional(),
    Port: portSchema,
    DN: z.string().min(1, { message: t('filials.authorization.dn') }),
    Domain: z.string().min(1, { message: t('errors.enterDomain') }),
    UserFilter: z.string().min(1, { message: t('errors.enterFilter') }),

    mapping: z.object({
      principal_name: z.string().min(1, { message: t('errors.enterGeneralName') }),
      login: z.string().min(1, { message: t('servers.addLogin') }),
      first_name: z.string().min(1, { message: t('errors.enterName') }),
      second_name: z.string().min(1, { message: t('errors.enterLastName') }),
      email: z
        .string()
        // .email({ message: 'Введите валидный адрес почты в формате:  example@mail.com' })
        .or(z.literal(''))
        .optional()
        .nullable(),
      phone: z
        .string()
        // .regex(phoneRegExp, { message: phoneRegExpErrorText })
        .or(z.literal(''))
        .optional()
        .nullable(),
      position: z.string().or(z.literal('')).optional().nullable(),
      organization: z.string().or(z.literal('')).optional().nullable(),
      city: z.string().or(z.literal('')).optional().nullable(),
      isDeleted: z.string().or(z.literal('')).optional().nullable(),
      teamNumber: z.string().or(z.literal('')).optional().nullable(),
      linkEmail: z.string().or(z.literal('')).optional().nullable()
    }),
    GroupEnabled: z.literal('false'),
    GroupDn: z.string().optional().nullable(),
    GroupFilter: z.string().optional().nullable(),
    GroupMapping: z.object({})
  });

  const enabledGroupExportSchema = authSchema.extend({
    useLDAP: z.literal('true'),
    GroupEnabled: z.literal('true'),
    GroupDn: z.string().min(1, { message: t('errors.enterDn') }),
    GroupFilter: z.string().min(1, { message: t('errors.enterGroupFilter') }),
    GroupMapping: z.object({
      Description: z.string().optional().nullable(),
      Name: z.string().min(1, { message: t('errors.enterName') }),
      Uid: z.string().min(1, { message: t('errors.enterId') }),
      Member: z.string().min(1, { message: t('errors.enterUserId') })
    })
  });

  const validateScheme = z.union([noAuthSchema, authSchema, enabledGroupExportSchema]);

  return { validateScheme };
};
