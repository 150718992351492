import { IListItem } from '@/types';

export enum ActionQuotaMenuEnum {
  edit = 'edit',
  delete = 'delete',
  changeTariff = 'chaneTariff'
}

const isDefaultQuota = (quotaId: number) => quotaId === 1;

export const actionMenu = (quotaId: number): IListItem[] => {
  const list: IListItem[] = [
    {
      label: 'Редактировать',
      value: ActionQuotaMenuEnum.edit
    }
  ];

  if (!isDefaultQuota(quotaId)) {
    list.push({
      label: 'Удалить',
      value: ActionQuotaMenuEnum.delete,
      color: 'brandRed.0'
    });
  }

  return list;
};
