import { Box, LoadingOverlay, Text } from '@mantine/core';
import { useStyles } from './styles';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { ConnectionItem } from './connection-item';
import { InfoIcon } from '@assets/icons';
import { showNotification } from '@mantine/notifications';
import { IActiveConnection } from '@/entities/admin-app/user';
import { EditUserInfoContext } from '../../../index';
import { useTerminateSessions } from '../../../../../../users/hooks/useTerminateSessions';
import { SectionTemplate } from '@/containers/pages/user/components/section-template';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '@/hooks/redux/redux';

export const ActiveConnections = () => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const { userInfoCommon, currentId } = useContext(EditUserInfoContext);
  const { Connections } = userInfoCommon;
  const [list, setList] = useState<IActiveConnection[]>(Connections?.length ? Connections : []);
  const allConnectionIds = useMemo(() => list?.map((item) => item.Id), [list]);
  const currentConnectionItem = list.find((item) => item.IsOwner);
  const currentUser = useAppSelector((state) => state.userInfo.User);
  const { closeSessions, isLoading } = useTerminateSessions();

  const isMyProfilePage = (pathname: string, userId?: number) => {
    const profilePathRegex = /^\/profile\/(\d+)\/safety$/;
    const match = pathname.match(profilePathRegex);
    return match && match[1] === userId?.toString();
  };

  const terminateConnection = useCallback(
    async (event: React.SyntheticEvent<HTMLButtonElement>) => {
      const id = Number(event.currentTarget.dataset.id) ?? null;
      if (currentConnectionItem && currentConnectionItem.Id === id) {
        showNotification({
          title: t('profile.cantDisconnectCurrentConnection'),
          message: '',
          autoClose: true,
          color: 'red'
        });
      } else {
        const updateList = () => {
          setList((prevState) => prevState.filter((item: IActiveConnection) => item.Id !== id));
        };
        await closeSessions({ ids: [id] }, updateList);
      }
    },
    []
  );

  const terminateAllConnections = useCallback(async () => {
    const filterConnectionsIds = allConnectionIds?.filter(
      (id: number) => id !== currentConnectionItem?.Id
    );

    if (filterConnectionsIds.length > 0) {
      const updateList = () => {
        setList((prevState) =>
          prevState.filter((item: IActiveConnection) => item.Id === currentConnectionItem?.Id)
        );
      };
      await closeSessions({ userId: currentId || null }, updateList);
    }
  }, [allConnectionIds]);

  useEffect(() => {
    if (Connections.length) {
      setList(Connections);
    }
  }, [Connections]);

  return (
    <SectionTemplate title={t('profile.activeConnections')}>
      <Box className={classes.container}>
        <>
          {list?.length ? (
            <>
              <Box className={classes.header}>
                <Text
                  className={classes.description}
                  onClick={terminateAllConnections}
                  data-cy="terminateAllConnections"
                >
                  {isMyProfilePage(location.pathname, currentUser?.Id)
                    ? list.length > 1
                      ? t('profile.terminateMyConnections')
                      : null
                    : t('profile.terminateAllConnections')}
                </Text>
                {isMyProfilePage(location.pathname, currentUser?.Id) && list.length === 1 ? null : (
                  <InfoIcon />
                )}
              </Box>
              {list?.map((item) => {
                return (
                  <ConnectionItem
                    key={item.Id}
                    {...item}
                    isCurrent={item.IsOwner}
                    terminateConnection={terminateConnection}
                  />
                );
              })}
            </>
          ) : (
            <Box className={classes.emptyData}>{t('profile.noActiveConnections')}</Box>
          )}
        </>
        <LoadingOverlay visible={isLoading} />
      </Box>
    </SectionTemplate>
  );
};
