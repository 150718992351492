import { IFiltersEnumType } from '@/types/enums';
export const BASE_URL = '/api';
export const ssoRedirectLink = '/api/v1/OAuth20/Code?type=mail';
export const kerberosRedirectLink = '/api/v2/auth/kerberos';
export const filtersLabels: Partial<Record<IFiltersEnumType, string>> = {
  1: 'Роль',
  2: 'Статус',
  3: 'Подразделения',
  4: 'Должность',
  5: 'Руководитель',
  6: 'Тип роли',
  7: 'Авторизация',
  8: 'Пол',
  9: 'Email',
  10: 'Местоположение',
  11: 'Дата рождения',
  12: 'Дата создания',
  13: 'Последний вход',
  14: 'Политика квот',
  15: 'Занято места, Мб',
  16: 'Пользователь',
  17: 'Действие'
};
export const filtersLabelsKeys: Partial<Record<IFiltersEnumType, string>> = {
  1: 'roleFilter',
  2: 'userStatusFilter',
  3: 'filialFilter',
  4: 'positionFilter',
  5: 'supervisorFilter',
  6: 'roleTypeFilter',
  7: 'UserTypeFilter',
  8: 'genderFilter',
  9: 'emailStatusFilter',
  10: 'addressFilter',
  11: 'birthDateFilter',
  12: 'dateOfCreateFilter',
  13: 'lastVisitDateFilter',
  14: 'quotaFilter',
  15: 'usingQuotaSizeFilter',
  16: 'userFilter',
  17: 'authActionTypeFilter'
};
