import { z } from 'zod';
import i18n from '@/entities/i18next';
export const validateSchemeBrandingCommon = z.object({
  CompanyName: z
    .string()
    .min(1, { message: i18n.t('validation.typeCompanyName') })
    .max(64, { message: i18n.t('validation.maxLengthSymbolsWithCount.symbols', { count: 64 }) }),
  MainLogoUrl: z.string().min(1, { message: i18n.t('validation.chooseLogo') }),
  BrandColor: z
    .string()
    .length(7, { message: i18n.t('validation.brandColorHEXFormat') })
    .regex(/^#/, { message: i18n.t('validation.brandColorHEXFormat') })
});

export const validateSchemeProductNotification = z.object({
  UpdateId: z.string().min(1, { message: i18n.t('validation.enterUpdateId') }),
  Version: z.string().min(1, { message: i18n.t('validation.enterVersion') }),
  ReleaseDate: z.string().min(1, { message: i18n.t('validation.enterReleaseDate') }),
  UpdateDate: z.string().min(1, { message: i18n.t('validation.enterUpdateDate') }),
  ImageUrl: z.string().min(1, { message: i18n.t('validation.enterImageUrl') }),
  Topic: z
    .string()
    .min(1, { message: i18n.t('validation.enterTitle') })
    .max(64, { message: i18n.t('validation.maxLengthSymbolsWithCount.symbols', { count: 64 }) }),
  Text: z
    .string()
    .min(1, { message: i18n.t('validation.enterTextMessage') })
    .max(256, { message: i18n.t('validation.maxLengthSymbolsWithCount.symbols', { count: 256 }) }),
  UpdateUrl: z.string().min(1, { message: i18n.t('validation.enterUpdateUrl') }),
  IsEnabled: z.boolean()
});
