import { FormErrors as DefaultFormErrors } from '@mantine/form';
import { ZodType } from 'zod';
export const emailRegExp = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
export const emailPrefixRegExp = /^[a-zA-Z0-9._-]{6,30}$/;
export const emailFirstCharRegExp = /^[a-zA-Z0-9]/;
export const emailLastCharRegExp = /[a-zA-Z0-9]$/;
export const rusRegExp = /^[А-Яа-яЁё`'.\-s]+$/;
export const phoneRegExp = /^((8|\+7)[- ]?)?(\(?\d{3}\)?[- ]?)?[\d\- ]{7,10}$/;

export const getValidateErrors = (validateScheme: ZodType, values: object): DefaultFormErrors => {
  const formErrors: any = {};
  const res = validateScheme.safeParse(values);
  if (!res.success) {
    const errors = res.error.errors;
    // in case it's invalid_union
    if (errors.some((e) => e.code === 'invalid_union')) {
      const unionErrors = errors
        .map((e) => {
          if ('unionErrors' in e) {
            return e.unionErrors.map((ue) => ue.errors);
          } else {
            return [];
          }
        })
        .flat(2);
      if (unionErrors.length) {
        unionErrors.forEach((uerr) => {
          const key = uerr.path.join('.');

          formErrors[key] = uerr.message;
        });
      }
    } else if (errors.length) {
      errors.forEach((err) => {
        const key = err.path.join('.');

        formErrors[key] = err.message;
      });
    }
  }

  return formErrors;
};
