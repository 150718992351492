import preval from 'preval.macro';

const versionType = preval`module.exports = (() => {
    if (process.env.NODE_ENV === 'development') {
      return 'DEV';
    }
    return 'PROD';
  })()`;

const versionNumber = Math.floor(preval`module.exports = Date.now();` / 1000);

const versionDate = preval`module.exports = new Date().toLocaleString('ru');`;

const shortCommitSha = preval(`
  var execSync = require('child_process').execSync;
  try {
    module.exports = execSync('git rev-parse --short HEAD').toString().trim();
  } catch (e) {
    module.exports = 'unknown';
  }
`);

export const getTimestamp = (version) => +version.split('-')[1];

export default preval(
  `module.exports = "${versionType}-${versionNumber}[${versionDate}]-${shortCommitSha}";`
);
