import { useCallback, useEffect, useState } from 'react';
import { IRowData } from '@components/simple-table/types';
import {
  useRemoveUsersMutation,
  useCheckRemoveStatusMutation
} from '@/entities/admin-app/users/api';
import { clearApiCacheByTags } from '@/entities/helpers';
import { useActions } from '../redux/action';
import { useAppSelector } from '../redux/redux';
import { usersApi, useLazyGetUsersQuery } from '@/entities/admin-app/users/api';
import { IRemoveResponse } from '@/entities/admin-app/users';
import { universalNotification } from '@/lib/utils/notification';
import { useTranslation } from 'react-i18next';

export enum DeletingResponse {
  SUCCESS_STATUS = 1,
  ERROR_STATUS = 2,
  PENDING_STATUS = 0
}

export const useDeleteUsers = (
  setRowData: (value: React.SetStateAction<IRowData[]>) => void,
  isMounted: React.MutableRefObject<boolean>,
  scrollRef: any
) => {
  const { t } = useTranslation();
  const { setUsers } = useActions();
  const { filters, users } = useAppSelector((state) => state.users);
  const [modalLoadingStatus, setModalLoadingStatus] = useState<boolean>(false);
  const [fetchGetUsers] = useLazyGetUsersQuery();
  const [removeUsers, { isLoading }] = useRemoveUsersMutation();
  const [checkRemoveStatus] = useCheckRemoveStatusMutation();

  const [checkingStatus, setCheckingStatus] = useState<boolean>(false);
  const [percentageComplete, setPercentageComplete] = useState<number>(0);

  const { setUsersTableFilters } = useActions();

  const { clearUsersSelected } = useActions();

  async function waitUsersStatus(response: { data: IRemoveResponse }) {
    let checkResponse = await checkRemoveStatus(response.data);
    setCheckingStatus(true);

    const updatePercentage = () => {
      if ('data' in checkResponse) {
        const completedUsers = checkResponse?.data?.filter(
          (item) => item?.Status !== DeletingResponse.PENDING_STATUS
        );
        const completedPercentage = (completedUsers.length / checkResponse.data.length) * 100;
        setPercentageComplete(completedPercentage);
      }
    };
    updatePercentage();
    while (
      'data' in checkResponse &&
      isMounted.current &&
      checkResponse?.data.some((item) => item?.Status === DeletingResponse.PENDING_STATUS)
    ) {
      await new Promise((resolve) => setTimeout(resolve, 2000));
      checkResponse = await checkRemoveStatus(response.data);
      updatePercentage();
    }
    return checkResponse;
  }

  function showDeleteNotifications(usersStatusResponse: {
    data: {
      Status: number;
      UserId: number;
    }[];
  }) {
    if (usersStatusResponse.data.some((item) => item.Status === DeletingResponse.SUCCESS_STATUS)) {
      universalNotification({
        isError: false,
        isSuccess: true,
        successTitle: t('users.deleteCompletedSuccessfully'),
        error: null
      });
      clearUsersSelected();
    }
    if (usersStatusResponse.data.some((item) => item.Status === DeletingResponse.ERROR_STATUS)) {
      const errorDeletingStatus = usersStatusResponse.data.filter(
        (item) => item.Status === DeletingResponse.ERROR_STATUS
      );
      const errorUsersId = errorDeletingStatus.map((item) => item.UserId);
      const errorUserNames = users
        .filter((user) => errorUsersId.includes(user.Id))
        .map((user) => `${user.Surname} ${user.FirstName}`)
        .join(', ');

      universalNotification({
        errorTitle: `${t('users.errorDuringDeletion')} :`,
        errorMessage: `${errorUserNames}`,
        isError: true,
        isSuccess: false,
        error: null
      });
    }
  }

  const handleDeleteUsers = useCallback(
    async (data: { UserId: number; TransferUserId: number[] }[]) => {
      try {
        const response = await removeUsers(data);
        if ('data' in response && response.data) {
          const usersStatusResponse = await waitUsersStatus(response);
          setCheckingStatus(false);
          clearApiCacheByTags(usersApi, ['Users']);
          if ('data' in usersStatusResponse && usersStatusResponse.data) {
            showDeleteNotifications(usersStatusResponse);
            scrollRef.current.el.scrollTo(0, 0);
            setUsersTableFilters({
              ...filters,
              Offset: 0
            });
            const { data } = await fetchGetUsers({
              Count: filters.Count,
              Offset: 0,
              SortOrder: filters.SortOrder,
              SortField: filters.SortField,
              Filters: filters.Filters,
              FilterConditions: filters.FilterConditions,
              Query: filters.Query
            });
            if (data) {
              setUsers(data);
              setRowData(data.Users);
            }
          } else if ('error' in usersStatusResponse) {
            universalNotification({
              error: (usersStatusResponse as any)?.error,
              isError: true,
              isSuccess: false
            });
          }
        } else if ('error' in response) {
          universalNotification({
            error: (response as any)?.error,
            isError: true,
            isSuccess: false
          });
        }
      } catch (error) {
        console.error('Error:', error);
      }
    },
    []
  );

  useEffect(() => {
    if (checkingStatus || isLoading) {
      setModalLoadingStatus(true);
    }
  }, [checkingStatus, isLoading]);

  return { handleDeleteUsers, modalLoadingStatus, percentageComplete };
};
