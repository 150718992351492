import { Avatar, AvatarProps, Box, Overlay, Text } from '@mantine/core';
import { CSSProperties, FC } from 'react';
import { useStyles } from './styles';
import { useTranslation } from 'react-i18next';

interface IAvatarWithTextProps extends AvatarProps {
  src: string;
  handleClick: () => void;
  overlayStyles?: CSSProperties;
}

export const AvatarWithOverlay: FC<IAvatarWithTextProps> = ({
  src,
  handleClick,
  className,
  overlayStyles,
  ...props
}) => {
  const { classes, cx } = useStyles();
  const { t } = useTranslation();
  const defaultOverlayStyles = {
    backgroundColor: '#000000',
    opacity: 0.6,
    borderRadius: 62,
    cursor: 'pointer',
    zIndex: 1
  };

  return (
    <Box className={classes.container} sx={{}}>
      <Avatar
        data-cy="avatar"
        src={src}
        className={cx(classes.avatar, className)}
        onClick={handleClick}
        {...props}
      />
      <Overlay style={overlayStyles || defaultOverlayStyles} onClick={handleClick}>
        <Text className={classes.text}>
          {src ? t('avatar.changeAvatar') : t('avatar.uploadPhoto')}
        </Text>
      </Overlay>
    </Box>
  );
};
