import { RoleTypesEnum } from '@/types/enums/roles';
import { showNotification } from '@mantine/notifications';
import { IFilterConditionItem } from '@/types/api';
import { IRowData, IUsersSortField } from '@/components/simple-table/types';
import { IUser } from '@/entities/admin-app/auth';
import { IRole } from '@/entities/admin-app/directories';

interface IRoleName {
  name: string;
}

export const getNameByRolesId = (role: RoleTypesEnum): IRoleName => {
  let name;
  switch (role) {
    case RoleTypesEnum.User:
      name = 'user';
      // name = 'Пользователь';
      break;
    case RoleTypesEnum.Admin:
      name = 'admin';
      // name = 'Админ';
      break;
    case RoleTypesEnum.SuperUser:
      name = 'superAdmin';
      // name = 'Супер-админ';
      break;
    case RoleTypesEnum.Guest:
      name = 'guest';
      // name = 'Гость';
      break;
    case RoleTypesEnum.All:
      name = 'all';
      // name = 'Все';
      break;
    case RoleTypesEnum.Support:
      name = 'support';
      // name = 'Поддержка';
      break;
    default:
      name = '';
  }
  return { name };
};

interface IGetAllNotification {
  error: any;
  isSuccess: boolean;
  isError: boolean;
  successMessage: string;
}

export const getAllNotification = (data: IGetAllNotification) => {
  const { error, isSuccess, isError, successMessage } = data;

  try {
    if (isSuccess) {
      showNotification({
        title: successMessage,
        message: '',
        autoClose: 10_000,
        color: 'green'
      });
    }

    if (isError) {
      showNotification({
        title: `${error.name} ${error?.ErrorCode}`,
        message: error?.ErrorMessage ?? '',
        autoClose: false,
        color: 'red'
      });
    }
  } catch (e) {
    const error = e as Error;
    showNotification({
      title: `${error.name}`,
      message: error.message,
      autoClose: 10_000,
      color: 'yellow'
    });
  }
};

export const convertToFilterConditions = (
  activeFilters: Record<number, number[] | Date[]>
): IFilterConditionItem[] => {
  const filterObjects: IFilterConditionItem[] = [];

  for (const [key, value] of Object.entries(activeFilters)) {
    const filterObject: IFilterConditionItem = {
      Type: Number(key)
    };

    if (key === '11' || key === '12' || key === '13') {
      const [from, to] = value as Date[];
      filterObject.DateRange = {
        From: from?.toISOString(),
        To: to.toISOString()
      };
    } else if (key === '15') {
      const [from, to] = value as number[];
      filterObject.NumRange = {
        From: from,
        To: to
      };
    } else {
      filterObject.NumValues = value as number[];
    }

    filterObjects.push(filterObject);
  }

  return filterObjects;
};

export const transformConditionals = (filters: IFilterConditionItem[]) => {
  const transformedFilters: Record<number, number[] | Date[]> = {};

  filters.forEach((filter) => {
    const { Type, NumValues, DateRange, NumRange } = filter;

    if (NumValues) {
      transformedFilters[Type] = NumValues;
    } else if (DateRange) {
      transformedFilters[Type] = [new Date(DateRange?.From), new Date(DateRange?.To)] as Date[];
    } else if (NumRange) {
      transformedFilters[Type] = [NumRange.From, NumRange.To];
    }
  });

  return transformedFilters;
};

export const getActiveFiltersLengths = (
  activeFilters: Record<number, number[] | Date[]>
): number => {
  let totalLength = 0;
  Object.entries(activeFilters).forEach(([key, values]) => {
    if (
      parseInt(key) === 11 ||
      parseInt(key) === 12 ||
      parseInt(key) === 13 ||
      parseInt(key) === 15
    ) {
      totalLength += 1;
    } else {
      totalLength += values.length;
    }
  });
  return totalLength;
};

export const transformNumberToRolesSortField = (value: IUsersSortField): keyof IRowData => {
  return value in IUsersSortField ? IUsersSortField[value] : IUsersSortField[IUsersSortField?.None];
};

export const transformRolesSortFieldToNumber = (
  value: keyof typeof IUsersSortField
): IUsersSortField => {
  return getValueByKeyForNumberEnum(value);
};

function getValueByKeyForNumberEnum(value: keyof typeof IUsersSortField) {
  const valueFromKey = Object.entries(IUsersSortField).find(([key]) => key === value)?.[1];
  return typeof valueFromKey === 'number' && valueFromKey in IUsersSortField
    ? valueFromKey
    : IUsersSortField.None;
}

export const transformObjectIfKeyIsNull = (obj: any) => {
  for (const key in obj) {
    if (obj[key].Id === null) {
      obj[key] = null;
    }
  }
  return obj;
};

export const getUserRoleFilters = (user?: IUser) => {
  const roleTypes = user?.Roles.map((role: IRole) => role?.Type);

  if (roleTypes?.includes(RoleTypesEnum.SuperUser)) {
    return [RoleTypesEnum.SuperUser, RoleTypesEnum.Admin, RoleTypesEnum.User];
  } else if (roleTypes?.includes(RoleTypesEnum.Admin)) {
    return [RoleTypesEnum.Admin, RoleTypesEnum.User];
  } else {
    return [RoleTypesEnum.User];
  }
};

export const limitsLengthRolesFields = {
  Name: 64
} as const;
