import { IRowData } from '@components/simple-table/types';
import { IListItem } from '@/types';
import { UserStatusEnum } from '@/types/enums/user';
import {
  BlockIcon,
  LockOpenIcon,
  EditProfileIcon,
  ConfirmMailIcon,
  AddRoleIcon,
  WriteMailIcon,
  DelIcon,
  LoginAsIcon,
  XIcon
} from '@assets/icons';
import { useTranslation } from 'react-i18next';

export enum ActionUserMenuEnum {
  loginAs = 'login-as',
  activateEmail = 'activate-email',
  block = 'block',
  unblock = 'unblock',
  deleteUser = 'delete-user',
  terminateAll = 'terminate-all',
  transferData = 'transfer-data',
  deletePersonalData = 'delete-personal-data',
  addRole = 'add-role',
  writeLetter = 'write-letter',
  editUser = 'profile'
}

export const actionMenu = (
  userStatus: UserStatusEnum,
  permissions: {
    showDeleteItem?: boolean;
    showLoginAsItem?: boolean;
    showChangeStatusItem?: boolean;
  } = {}
): IListItem[] => {
  const { t } = useTranslation();
  const baseList: IListItem[] = [
    {
      label: t('users.actionList.editProfile'),
      value: ActionUserMenuEnum.editUser,
      icon: <EditProfileIcon />
    },
    {
      label: t('users.actionList.confirmEmail'),
      value: ActionUserMenuEnum.activateEmail,
      icon: <ConfirmMailIcon />
    },
    {
      label: t('users.actionList.writeLetter'),
      value: ActionUserMenuEnum.writeLetter,
      icon: <WriteMailIcon />
    },
    {
      label: t('users.actionList.addRole'),
      value: ActionUserMenuEnum.addRole,
      icon: <AddRoleIcon />
    },
    {
      label: t('users.actionList.terminateConnections'),
      value: ActionUserMenuEnum.terminateAll,
      icon: <XIcon />
    }
  ];

  const list = [...baseList];

  if (userStatus !== UserStatusEnum.Ban) {
    if (permissions?.showLoginAsItem) {
      list.unshift({
        label: t('users.actionList.loginAs'),
        value: ActionUserMenuEnum.loginAs,
        icon: <LoginAsIcon />
      });
    }
    if (permissions?.showChangeStatusItem) {
      list.push({
        label: t('users.actionList.block'),
        value: ActionUserMenuEnum.block,
        icon: <BlockIcon />
      });
    }
  }

  if (userStatus === UserStatusEnum.Ban && permissions?.showChangeStatusItem) {
    list.push({
      label: t('users.actionList.unblock'),
      value: ActionUserMenuEnum.unblock,
      icon: <LockOpenIcon />
    });
  }

  if (permissions?.showDeleteItem) {
    list.push({
      label: t('users.actionList.delete'),
      value: ActionUserMenuEnum.deleteUser,
      icon: <DelIcon />
    });
  }

  return list;
};

export const row: IRowData[] = [
  {
    Id: '1',
    Checked: 'false',
    Name: 'Плотникова Полина',
    FirstName: 'Полина',
    LastName: 'Плотникова',
    MiddleName: 'Павловна',
    Sex: 'female',
    DateOfBirth: '12.12.2000',
    Email: 'jackson.graham@example.com',
    Department: 'Продажи',
    DepartmentValue: 'sale',
    Position: 'Заведующий хозяйством',
    Type: 'admin',
    Phone: '+7 (903) 880-93-38',
    UserStatus: 'admin',
    Avatar:
      'https://avatars.githubusercontent.com/u/10353856?s=460&u=88394dfd67727327c1f7670a1764dc38a8a24831&v=4'
  },
  {
    Id: '2',
    Checked: 'false',
    Name: 'Батыргареев Станислав',
    FirstName: 'Станислав',
    LastName: 'Батыргареев',
    MiddleName: 'Павлов',
    Sex: 'male',
    DateOfBirth: '10.05.1990',
    Email: 'stanislawbatyrgareev@gmail.com',
    Department: 'Контроль качества',
    DepartmentValue: 'quality-control',
    Position: 'Директор по цифровым технологиям',
    Type: 'worker',
    Phone: '+7 915 000-00-05',
    UserStatus: 'worker',
    Avatar:
      'https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=250&q=80'
  },
  {
    Id: '3',
    Checked: 'false',
    Name: 'Майорова София',
    FirstName: 'София',
    LastName: 'Майорова',
    MiddleName: 'Сергеевна',
    Sex: 'female',
    DateOfBirth: '10.05.1990',
    Email: 'tanya.hill@example.com',
    Department: 'Финансы',
    DepartmentValue: 'finance',
    Position: 'Директор по логистике',
    Type: 'guest',
    Phone: '+7 (903) 840-31-53',
    UserStatus: 'guest',
    Avatar:
      'https://images.unsplash.com/photo-1508214751196-bcfd4ca60f91?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=255&q=80'
  }
];
