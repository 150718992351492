import { FC, useEffect } from 'react';
import {
  Box,
  Flex,
  Grid,
  Text,
  TextInput,
  NumberInput,
  PasswordInput,
  Button,
  Checkbox
} from '@mantine/core';
import { FormErrors, useForm } from '@mantine/form';
import { z } from 'zod';

import { useAppSelector } from '@hooks/redux/redux';

import { getValidateErrors } from '@/lib/utils/validation';

import { useStyles } from '../../../styles';

interface ISMTPForm {
  host: string;
  port?: number;
  authentication: boolean;
  login: string;
  password: string;
  senderName: string;
  senderAddress: string;
  useSSL: boolean;
}

const basicSchema = z.object({
  host: z.string().min(1, { message: 'Введите хост' }),
  port: z
    .number({
      required_error: 'Введите порт',
      invalid_type_error: 'Порт должен быть числом'
    })
    .positive({ message: 'Должен быть положительным' })
    .lt(65536, { message: 'Должен быть меньше 65536' }),
  senderName: z.string().optional(),
  senderAddress: z.string().email({ message: 'Введите адрес отправителя' }),
  useSSL: z.boolean().optional()
});
const noAuthSchema = basicSchema.extend({
  authentication: z.literal(false),
  login: z.literal(''),
  password: z.literal('')
});
const authSchema = basicSchema.extend({
  authentication: z.boolean(),
  login: z.string().min(1, { message: 'Введите логин' }),
  password: z.string().min(1, { message: 'Введите пароль' })
});

const validateScheme = z.union([noAuthSchema, authSchema]);

const SMTPPage: FC = () => {
  const { classes } = useStyles();

  const { filial } = useAppSelector((state) => state.filials);

  const form = useForm<ISMTPForm>({
    initialValues: {
      host: '',
      authentication: false,
      login: '',
      password: '',
      senderName: '',
      senderAddress: '',
      useSSL: false
    },
    validateInputOnChange: true,
    validate: (values: ISMTPForm): FormErrors => getValidateErrors(validateScheme, values)
  });

  useEffect(() => {
    const { Smtp } = filial?.Settings || {};
    if (!Smtp) {
      return;
    }

    form.setValues({
      host: Smtp.Host,
      port: Smtp.Port,
      login: Smtp.Login,
      password: Smtp.Password,
      senderName: Smtp.ViewUserName,
      senderAddress: Smtp.SenderAddres
    });
  }, [filial]);

  const handleSubmit = () => {
    if (!filial) {
      return;
    }

    // saveSettings(filial.Id, SettingsEnum.Smtp, {
    //   Smtp: {
    //     Host: formValues.host,
    //     Port: formValues.port || 0,
    //     Login: formValues.login,
    //     Password: formValues.password,
    //     ViewUserName: formValues.senderName,
    //     SenderAddres: formValues.senderAddress
    //   }
    // });
  };

  return (
    <Box className={classes.container}>
      <Flex className={classes.section} align="flex-start" direction="column" px="sm" pb="md">
        <form onSubmit={form.onSubmit(handleSubmit)}>
          <Text className={classes.title} my="sm">
            Настройка SMTP
          </Text>

          <Grid columns={10}>
            <Grid.Col span={5}>
              <TextInput
                className={classes.textFieldInput}
                label="Хост"
                withAsterisk
                {...form.getInputProps('host')}
              />
            </Grid.Col>

            <Grid.Col span={2}>
              <NumberInput
                autoComplete="off"
                className={classes.textFieldInput}
                label="Порт"
                hideControls
                withAsterisk
                {...form.getInputProps('port')}
              />
            </Grid.Col>

            <Grid.Col span={10}>
              <Checkbox
                className={classes.checkbox}
                label="Аутентификация"
                size="xs"
                {...form.getInputProps('authentication')}
              />
            </Grid.Col>

            <Grid.Col span={5}>
              <TextInput
                className={classes.textFieldInput}
                label="Логин"
                withAsterisk
                disabled={!form.getTransformedValues().authentication}
                {...form.getInputProps('login')}
              />
            </Grid.Col>

            <Grid.Col span={5}>
              <PasswordInput
                className={classes.textFieldInput}
                label="Пароль"
                withAsterisk
                disabled={!form.getTransformedValues().authentication}
                {...form.getInputProps('password')}
              />
            </Grid.Col>

            <Grid.Col span={5}>
              <TextInput
                className={classes.textFieldInput}
                label="Отображаемое имя отправителя"
                {...form.getInputProps('senderName')}
              />
            </Grid.Col>

            <Grid.Col span={5}>
              <TextInput
                className={classes.textFieldInput}
                label="Адрес email отправителя"
                withAsterisk
                {...form.getInputProps('senderAddress')}
              />
            </Grid.Col>

            <Grid.Col span={10}>
              <Checkbox
                className={classes.checkbox}
                label="Использовать SSL"
                size="xs"
                {...form.getInputProps('useSSL')}
              />
            </Grid.Col>
          </Grid>

          <Text className={classes.description} mt="sm">
            Настройки SMTP нужны для того чтобы задать учетную запись электронной почты, которая
            будет служить для отправки оповещений с портала с помощью вашего собственного
            SMTP-сервера вместо используемого Р7-Демо по умолчанию. Пожалуйста, заполните все поля и
            нажмите на кнопку Сохранить. Вы можете использовать кнопку Тестовое письмо, чтобы
            проверить, все ли параметры введены правильно и всё ли работает как полагается.
          </Text>

          <Text className={classes.description} mt="sm">
            Если Вы используете учетную запись Gmail в качестве SMTP-сервера, Вы не сможете
            отправлять почту, пока не выполните следующие действия: войдите в аккаунт Gmail через
            стандартный веб-интерфейс и подтвердите запросы на проверку о попытке доступа Р7-Демо к
            вашему аккаунту, если они есть. Также необходимо сгенерировать пароль приложения в
            настройках безопасности на странице My Google Account (этот пароль можно использовать,
            только если включена двухэтапная верификация).Для отправки сообщений рекомендуется
            использовать порты 587 или 25, поскольку другие значения могут препятствовать правильной
            работе SMTP.
          </Text>

          <Button
            type="submit"
            size="sm"
            radius={8}
            disabled={!form.isValid()}
            mt="md"
            style={{ width: '20%' }}
          >
            Сохранить
          </Button>
        </form>
      </Flex>
    </Box>
  );
};

export default SMTPPage;
