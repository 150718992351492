export enum UsersActionTableEnum {
  role = 'role',
  department = 'department',
  status = 'status',
  sendActivationLinkAgain = 'send-activation-link-again',
  writeLetter = 'write-letter',
  removedUser = 'removed-user',
  export = 'export',
  addRoles = 'add-roles',
  resetPassword = 'reset-password',
  delete = 'delete',
  filial = 'filial'
}

export enum UsersNavbarMenuItemEnum {
  active = 'active',
  department = 'department',
  type = 'type',
  email = 'email'
}

export enum ProfileFilterTypeEnum {
  None = 0,
  Active = 1,
  Type = 2,
  Department = 3,
  Email = 4
}

export interface IUsersInitialFilters {
  [key: string]: number[];
}

export enum ProfileUpdateTypeEnum {
  None = 0,
  Credentials = 1,
  Common = 2,
  Customer = 3,
  Positions = 4,
  Roles = 5,
  Quota = 6,
  Status = 7,
  Email = 8,
  Avatar = 9,
  CredentialEmail = 10,
  AdditionalEmail = 11,
  TimeZone = 12,
  Language = 13
}

export enum ProfileSuccessMessageEnum {
  credentialEmailUpdate = 10
}

export enum ProfileSectionEnum {
  None = 'None',
  Credentials = 'Credentials',
  Common = 'Common',
  Customer = 'Customer',
  Positions = 'Positions',
  Roles = 'Roles',
  Quota = 'Quota',
  Status = 'Status',
  LinkedEmail = 'LinkedEmail',
  Avatar = 'Avatar',
  CredentialEmail = 'CredentialEmail',
  TimeZone = 'TimeZone',
  Language = 'Language'
}
