import { Box, Button, UnstyledButton } from '@mantine/core';
import { useStyles } from './styles';
import { UseFormReturnType } from '@mantine/form';
import { IUserInfo } from '@/entities/admin-app/user';
import { useTranslation } from 'react-i18next';
import { useConfirmCancelForm } from '@/containers/pages/user/feature/create-user/hooks/useConfirmCancelForm';

interface IControlProps {
  form: UseFormReturnType<IUserInfo>;
  isLoading: boolean;
}
export const Control = ({ form, isLoading }: IControlProps) => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const errors = form.errors;
  const isDirtyForm = form.isDirty();

  const isDisabled = Object.keys(errors).length !== 0 || !isDirtyForm;

  const handleCancelForm = useConfirmCancelForm();

  return (
    <Box className={classes.root}>
      <Box className={classes.control}>
        <Button
          size="sm"
          radius={8}
          type="submit"
          mr={16}
          disabled={isDisabled}
          loading={isLoading}
          data-cy="createUser"
        >
          {t('create')}
        </Button>

        <UnstyledButton
          onClick={handleCancelForm}
          data-cy="cancelCreateUser"
          className={classes.groupButton}
        >
          {t('cancel')}
        </UnstyledButton>
      </Box>
    </Box>
  );
};
