import { Dispatch, SetStateAction, useCallback, useEffect } from 'react';

import { IRowData } from '@components/simple-table/types';
import { useChangeRolesMutation } from '@/entities/admin-app/user/api';
import { useLazyGetUsersQuery } from '@/entities/admin-app/users/api';
import { useActions } from '../redux/action';
import { useAppSelector } from '../redux/redux';
import { universalNotification } from '@/lib/utils/notification';

export const useChangeRoles = (
  setRowData: Dispatch<SetStateAction<IRowData[]>>,
  selection: number[]
) => {
  const { setUsers } = useActions();
  const { filters } = useAppSelector((state) => state.users);

  const [fetchChangeRoles, resultChangeRoles] = useChangeRolesMutation();
  const [fetchGetUsers] = useLazyGetUsersQuery();

  const changeRoles = useCallback(
    async (selection: number[], roleIds: number[]) => {
      const numbersSelection = selection?.map((item) => Number(item)) ?? [];
      const roleIdsUnique = new Set(roleIds);

      try {
        const res = await fetchChangeRoles({
          UserIds: numbersSelection,
          RoleIds: [...roleIdsUnique]
        });

        if (!Object.keys(res).includes('error')) {
          const { data } = await fetchGetUsers({
            Count: filters.Count,
            Offset: 0,
            SortOrder: filters.SortOrder,
            SortField: filters.SortField,
            Filters: filters.Filters,
            Query: filters.Query
          });

          if (setRowData && data) {
            setUsers(data);
            setRowData(data.Users);
          }
        }
      } catch (err: any) {
        console.warn('fetchGetUsers ERROR', err);
      }
    },
    [selection]
  );

  useEffect(() => {
    const { isSuccess, isError, error } = resultChangeRoles;
    const _errorStatus = (error as any)?.data;

    universalNotification({
      isSuccess: isSuccess,
      isError: isError,
      error: _errorStatus,
      successTitle: 'Роли пользователей успешно обновлены'
    });
  }, [resultChangeRoles]);

  return { changeRoles };
};
