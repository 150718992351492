import { Flex } from '@mantine/core';

import { IPSecurityUsers } from './components/ip-security-users';
import { IPSecurityAdm } from './components/ip-security-adm';
import { SessionDuration } from './components/session-duration';
import { PasswordLimit } from './components/password-limit';
import { PasswordStrength } from './components/password-strength';
import { useStyles } from '../../../styles';
import { isSaaS } from '@/lib/utils/access';
import { useAppSelector } from '@hooks/redux/redux';

const SecurityPage = () => {
  const { classes } = useStyles();
  const { Settings } = useAppSelector((state) => state.userInfo);

  return (
    <Flex className={classes.container} direction="column">
      <IPSecurityUsers />
      <IPSecurityAdm />
      <SessionDuration />
      <PasswordLimit />
      {!isSaaS(Settings) && <PasswordStrength />}
    </Flex>
  );
};

export default SecurityPage;
